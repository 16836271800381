import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import setAuthorizationToken from './utils/set.authorization.token';
import {logout} from './actions/auth';
import {getCurrentUser} from './actions/current.user';
import AppRouter from './routers/AppRouter';
import configureStore from './store/config.store';
import './images/logo-revhoteles.png';
import './images/bg.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'normalize-css/normalize.css';
import './styles/styles.scss';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import 'open-iconic/font/css/open-iconic-bootstrap.min.css'

setAuthorizationToken(localStorage.revhoteles_jwt);

const store = configureStore();

if(!localStorage.revhoteles_jwt){
    store.dispatch(logout());
}

if(store.getState().auth.logged){
    store.dispatch(getCurrentUser());
}

const jsx = (
    <Provider store={store}>
        <AppRouter />
    </Provider>
);

ReactDOM.render(jsx, document.getElementById('app'));
