import React from 'react';
import {Bar} from 'react-chartjs-2';

const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'My First dataset',
            fill: false,
            lineTension: 0.1,
            backgroundColor: ['rgba(75, 192, 132, 0.6)','rgb(255,159,64,0.6)', 'rgba(255,99,132,0.6)', 'rgba(153,102,255,0.6)', 'rgba(255,206,86,0.6)'],
            borderColor: 'rgba(75,192,132,0.6)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [65, 59, 80, 81, 56, 55, 40]
        }
    ]
};


const ScatterChart = () =>

    (
        <div>
            <h2>Bar Example</h2>
            <Bar data={data}/>
        </div>
    );


export default ScatterChart;

