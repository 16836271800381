import React from 'react';

class TextField extends React.Component {
    render() {
        const meta = this.props.meta;
        return (
            <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
                <label className={`control-label ${this.props.required ? 'required' : ''}`}>
                    {this.props.label} {this.props.required ? <span>*</span> : ''}
                </label>
                <input {...this.props.input} placeholder={this.props.placeholder} type={this.props.type} autoComplete={this.props.autocomplete} className={`form-control ${meta.touched && meta.error ? 'has-error' : ''}`} />
                {meta.touched && meta.error &&
                <small className="form-text text-danger">{meta.error}</small>
                }
            </div>
        );
    }
}

export default TextField;