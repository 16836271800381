import React, {Component} from 'react';
import {MdEdit} from 'react-icons/md';
import {Button, Modal, ModalHeader, Tooltip} from 'reactstrap';
import RoomForm from './room.form';
import {connect} from 'react-redux';
import {editRoom} from '../../actions/rooms';
import {setCurrentHotel} from '../../actions/current.hotel';

class EditRoomButton extends Component {
    constructor(props) {
        super(props);
        this.state = {openModal: false, tooltipOpen: false}
    }

    toggleModal = (event) => {
        event.preventDefault();
        this.setState(() => ({
            openModal: !this.state.openModal,
        }))
    };

    toggle = () => {
        this.setState((prevState) => ({
            tooltipOpen: !prevState.tooltipOpen
        }))
    }

    submit = (values) => {
        delete values.hotel_name;
        const roomInfo = JSON.stringify(values, null, 2);
        this.props.editRoom(this.props.roomId, roomInfo);

        this.props.onSetHotels().then(() => {
            if(values.hotel_id === this.props.currentHotelId){
                const hotel_selected = this.props.hotels.data.find((it) => it.id == values.hotel_id);
                localStorage.current_hotel = JSON.stringify(hotel_selected);
                this.props.setCurrentHotel(hotel_selected);
            }
        });

        this.setState(() => ({
            openModal: false
        }))
    };

    render() {
        const {openModal} = this.state;

        return (
            <div>
                <Button
                    id={"editRoomButton"+this.props.roomId}
                    color="info"
                    onClick={this.toggleModal}
                    className="ml-3"
                >
                    <MdEdit />
                </Button>
                <Tooltip
                    placement="bottom"
                    isOpen={this.state.tooltipOpen}
                    target={"editRoomButton"+this.props.roomId}
                    toggle={this.toggle}
                    trigger="hover"
                    delay={{ show: 10, hide: 50 }}
                >
                    Editar habitación
                </Tooltip>
                <Modal isOpen={openModal} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>
                        Editar Habitación
                    </ModalHeader>
                    <RoomForm
                        hotels={this.props.hotels}
                        toggleModal={this.toggleModal}
                        onSubmit={this.submit}
                        roomId={this.props.roomId}
                    />
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    hotels: state.hotels,
    currentHotelId: state.current_hotel.hotel_info.id
});


const mapDisptachToProps = (dispatch) => ({
    editRoom: (roomId, roomInfo) => dispatch(editRoom(roomId, roomInfo)),
    setCurrentHotel: (hotelInfo) => dispatch(setCurrentHotel(hotelInfo))
});


export default connect(mapStateToProps, mapDisptachToProps)(EditRoomButton);