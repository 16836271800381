import React, {Component} from 'react';
import {NUMBER_OF_YEARS} from '../../utils/constants';
import moment from 'moment';
import { FormGroup, Label, Input} from 'reactstrap';

export class YearSelector extends Component{
    constructor(props){
        super(props);
        moment.locale('es');
        this.state = {currentYear: moment().format('YYYY'), yearSelected: 0}
    }

    handleYearChange = (event) => {
        const yearSelected = event.target.value;
        this.setState(() => ({
            yearSelected
        }))
        this.props.onYearChanged(yearSelected);
    }

    render() {
        let options = [<option key={0} value={0}>Ninguno</option>];
        for(let i=0 ; i < NUMBER_OF_YEARS ;i++){
            options.push(<option key={i + 1} value={this.state.currentYear - i - 1}>{ this.state.currentYear - i - 1}</option>)
        }
        return (
            <FormGroup className="year-comparison">
                <Label for="yearSelector">Año de comparación</Label>
                <Input type="select" name="select" id="yearSelector" value={this.state.yearSelected} onChange={this.handleYearChange}>
                    {options}
                </Input>
            </FormGroup>
        )
    }
}

export default YearSelector;