import React, {Component} from 'react';
import LineChart from '../charts/line.chart';
import PieChart from '../charts/pie.chart';
import ScatterChart from '../charts/scatter.chart';
import {connect} from 'react-redux';

class Dashboard extends Component{
    componentDidMount(){

    }

    render(){
        return (
            <div>
                <div className="row">
                    <div className="column">
                        <h1>Hotel {this.props.current_hotel.name}</h1>
                    </div>
                </div>
                <div>
                    <LineChart />
                </div>
                <div>
                    <PieChart />
                </div>

                <div>
                    <ScatterChart />
                </div>
            </div>
        )
    }
};

const mapStateToProps = (state) => ({
    current_hotel: state.current_hotel.hotel_info
});



export default connect(mapStateToProps)(Dashboard);