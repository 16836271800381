import React, {Component} from 'react';
import {connect} from 'react-redux';
import UserList from './user.list';
import {startSetRoles} from '../../actions/roles';
import {startSetUsers} from '../../actions/users';


class Users extends Component {

    componentDidMount() {
        this.props.setRoles();
        this.props.setUsers();
    }


    render() {
        return (
            <div className="container">
                <UserList openModal={this.props.openModal}/>
            </div>
        )
    }
};

const mapDispatchToProps = (dispatch) => ({
    setRoles: () => dispatch(startSetRoles()),
    setUsers: () => dispatch(startSetUsers())
});


export default connect(undefined, mapDispatchToProps)(Users);