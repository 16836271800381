export const hasPermission = (userPermissions, subject, action) => {
    if (!userPermissions || !subject || !action) {
        return false;
    }

    return userPermissions.filter((permission) => {
            return permission.subject === subject && permission.action === action;
        }).length > 0;
};

/*
 Expects permissions to be an array of objects with the key equal the the subject name and the value equal to
 the expected permissionAction.
 */
export const hasPermissionForEach = (userPermissions, permissions) => {
    if (!userPermissions || !permissions) {
        return false;
    }

    const missingPermission = permissions.some((permission) => {
        const keys = Object.keys(permission);

        return keys.length > 1 || !hasPermission(userPermissions, keys[0], permission[keys[0]]);
    });

    return !missingPermission;
};