import React, {Component} from 'react';
import {MdEdit} from 'react-icons/md';
import {Button, Modal, ModalHeader, Tooltip} from 'reactstrap';
import UserForm from './user.form';
import {connect} from 'react-redux';
import {editUser} from '../../actions/users';
import Roles from '../../roles';
import {SubmissionError} from 'redux-form';

class EditUserButton extends Component {
    constructor(props) {
        super(props);
        this.state = {openModal: false, tooltipOpen: false}
    }

    toggleModal = (event) => {
        event.preventDefault();
        this.setState(() => ({
            openModal: !this.state.openModal,
        }))
    };

    toggle = () => {
        this.setState((prevState) => ({
            tooltipOpen: !prevState.tooltipOpen
        }))
    }

    submit = (values) => {

        //Encuentro los usuarios que son administradores
        const usersAdministrator = this.props.users.filter((user) => {
            return user.role.id === Roles.ADMIN.id
        });

        //Verifico que rol tiene el usuario que deseo editar
        const userRole = this.props.users.find((user) => user.id == this.props.userId).role.id;

        //Determino si el usuario puede ser editado
        let canEditUser = userRole === Roles.ADMIN.id ? usersAdministrator.length - 1 : true;


        if (parseInt(values.role_id) === Roles.ADMIN.id || (parseInt(values.role_id) !== Roles.ADMIN.id && canEditUser)) {
            delete values.confirmPassword;
            const userInfo = JSON.stringify(values, null, 2);
            this.props.editUser(this.props.userId, userInfo);
            this.setState(() => ({
                openModal: false
            }))
        } else {
            throw new SubmissionError({
                role_id: 'Es el último administrador, su rol no puede ser cambiado.'
            });
        }
    };

    render() {
        const {openModal} = this.state;

        return (
            <div>
                <Button
                    id={"editUserButton"+this.props.userId}
                    color="info"
                    onClick={this.toggleModal}
                    className="ml-3"
                >
                    <MdEdit />
                </Button>
                <Tooltip
                    placement="bottom"
                    isOpen={this.state.tooltipOpen}
                    target={"editUserButton"+this.props.userId}
                    toggle={this.toggle}
                    trigger="hover"
                    delay={{ show: 10, hide: 50 }}
                >
                    Editar usuario
                </Tooltip>
                <Modal isOpen={openModal} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>
                        Editar Usuario
                    </ModalHeader>
                    <UserForm
                        roles={this.props.roles}
                        toggleModal={this.toggleModal}
                        onSubmit={this.submit}
                        userId={this.props.userId}
                    />
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    roles: state.roles,
    users: state.users.data
});

const mapDisptachToProps = (dispatch) => ({
    editUser: (userId, userInfo) => dispatch(editUser(userId, userInfo))
});


export default connect(mapStateToProps, mapDisptachToProps)(EditUserButton);