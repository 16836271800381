import React from 'react';
import {Router, Route, Switch} from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';
import LoginPage from '../components/login/login.page';
import Dashboard from '../components/dashboard/dashboard';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import NotFoundPage from '../components/404/not.found.page';
import UploadFile from '../components/dataUpload/load.csv.file';
import ForecastPage from '../components/forecast/forecast.page';
import Reports from '../components/reports/reports';
import Hotels from '../components/hotels/hotels';
import Users from '../components/users/users';
import Rooms from '../components/rooms/rooms';
import HotelUsersPage from '../components/hotelUsers/hotel.users.page';

export const history = createHistory();

const AppRouter = () => (
    <Router history={history}>
        <div>
            <Switch>
                <PublicRoute path='/' component={LoginPage} exact={true}/>
                <PrivateRoute path='/hotel/users' component={HotelUsersPage} />
                <PrivateRoute path='/dashboard' component={Dashboard}/>
                <PrivateRoute path='/forecast' component={ForecastPage}/>
                <PrivateRoute path='/reports' component={Reports}/>
                <PrivateRoute path='/upload-file' component={UploadFile}/>
                <PrivateRoute path='/hotels' component={Hotels}/>
                <PrivateRoute path='/users' component={Users}/>
                <PrivateRoute path='/rooms' component={Rooms}/>
                <Route component={NotFoundPage}/>
            </Switch>
        </div>
    </Router>
);

export default AppRouter;

