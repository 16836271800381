export const ADD_USER = "ADD_USER";
export const ADD_HOTEL = "ADD_HOTEL";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const CLEAR_ERROR_MESSAGE = "CLEAR_ERROR_MESSAGE";
export const CLEAR_CURRENT_USER = "CLEAR_CURRENT_USER";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const EDIT_USER = "EDIT_USER";
export const EDIT_HOTEL = "EDIT_HOTEL";
export const PRICES_LOG_SAVED = "PRICES_LOG_SAVED";
export const REMOVE_USER = "REMOVE_USER";
export const REMOVE_HOTEL = "REMOVE_HOTEL";
export const REQUEST_USERS = "REQUEST_USERS";
export const REQUEST_HOTELS = "REQUEST_HOTELS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_CURRENT_HOTEL = "SET_CURRENT_HOTEL";
export const SET_FORECAST_SUCCESS = "SET_FORECAST_SUCCESS";
export const SET_FORECAST_FAILURE = "SET_FORECAST_FAILURE";
export const SET_FORECAST_REQUEST = "SET_FORECAST_REQUEST";
export const SET_USERS = "SET_USERS";
export const SET_HOTELS = "SET_HOTELS";
export const SET_ROLES = "SET_ROLES";
export const SET_ROOMS = "SET_ROOMS";
export const ADD_ROOM = "ADD_ROOM";
export const EDIT_ROOM = "EDIT_ROOM";
export const REMOVE_ROOM = "REMOVE_ROOM";
export const REQUEST_ROOMS = "REQUEST_ROOMS";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT = "LOGOUT";
export const UPDATE_HOTEL = "UPDATE_HOTEL";
export const UPLOAD_CSV_FILE = "UPLOAD_CSV_FILE";
export const UPDATE_PRICE_LOG = "UPDATE_PRICE_LOG";