import React, {Component} from 'react';
import {connect} from 'react-redux';
import RoomList from './room.list';
import {startSetHotels} from '../../actions/hotels';
import {startSetRooms} from '../../actions/rooms';


class Rooms extends Component {

    componentDidMount() {
        this.props.setRooms();
        this.props.setHotels();
    }


    render() {
        return (
            <div className="container">
                <RoomList openModal={this.props.openModal}/>
            </div>
        )
    }
};

const mapDispatchToProps = (dispatch) => ({
    setRooms: () => dispatch(startSetRooms()),
    setHotels: () => dispatch(startSetHotels())
});


export default connect(undefined, mapDispatchToProps)(Rooms);