import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Table, Card, CardTitle, CardBody} from 'reactstrap';
import RoomItem from './room.item';
import ListViewHeading from '../listView/list.view.heading';
import AddRoomButton from './add.room.button';
import {LoadingShading} from '../loadingIndicator/loading.shading';
import AlertMessages from '../messages/alert.messages';
import NoResultsFound from '../noResultsFound/no.results.found';

const columns = [
    {
        name: "Nombre",
        column: "Name",
        columnClass: "th-column"
    },
    {
        name: "Cantidad",
        column: "Quantity",
        columnClass: "th-column text-center"
    },
    {
        name: "Cantidad max de huesped",
        column: "MaxQuantityGuest",
        columnClass: "th-column text-center"
    },
    {
        name: "Nombre corto",
        column: "ShortName",
        columnClass: "th-column text-center"
    },
    {
        name: "Hotel",
        column: "Hotel",
        columnClass: "th-column"
    },
    {
        name: "Acciones",
        column: "actions",
        columnClass: "action-items e-th-column",
    }
];

class RoomList extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { rooms } = this.props;

        return (
            <div>
                { rooms.message.text && <AlertMessages message={rooms.message}/> }
                <div className="mt-1 items-list-grid">
                    <Card className="mb-1">
                        <CardBody>
                            <div className="row align-items-center mb-4">
                                <div className="col-xs-12 col-sm-12 col-md-7 col-lg-8">
                                    <CardTitle className="mb-0">
                                        Listado de habitaciones
                                    </CardTitle>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-5 col-lg-4 text-right">
                                    <AddRoomButton />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-lg-12">
                                    <Table responsive className="mb-0">
                                        <thead>
                                        <ListViewHeading
                                            columns={columns}
                                        />
                                        </thead>
                                        <tbody>
                                        {
                                            rooms.data.map((room) => {
                                                return <RoomItem
                                                    key={room.id}
                                                    {...room}
                                                />;
                                            })
                                        }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            { rooms.isLoadingRooms && <div className="modal-backdrop fade show bg-White"></div>}
                            { rooms.isLoadingRooms && <LoadingShading />}
                            { !rooms.isLoadingRooms && rooms.data.length === 0 && <NoResultsFound />}
                        </CardBody>
                    </Card>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    rooms: state.rooms,
});


export default connect(mapStateToProps)(RoomList);

