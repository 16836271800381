import React from 'react';
import CSVReader from 'react-csv-reader';
import PropTypes from 'prop-types';

const CSVFileReader = ({label, onDataLoaded, onError}) => (
    <CSVReader
        cssClass="csv-input-file"
        label={label}
        onFileLoaded={onDataLoaded}
        onError={onError}
        inputId="csv-input-text"
    />
);

CSVFileReader.propTypes = {
    label: PropTypes.string.isRequired,
    onDataLoaded: PropTypes.func.isRequired,
    onError: PropTypes.func
};

export default CSVFileReader;

