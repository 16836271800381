import React from 'react';
import PropTypes from 'prop-types';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';

class ListViewColumn extends React.Component {
    handleSelected = () => {
        if (this.props.onSelected && this.props.isSortable) {
            this.props.onSelected(this.props.column);
        }
    }

    render() {
        const isActiveColumn = this.props.activeColumn === this.props.column;
        const isSortable = this.props.isSortable;
        let sortIcon;

        if (isSortable && isActiveColumn) {
            if ( this.props.sortDirection === 'desc') {
                sortIcon = <span className="ct-icon-tiny spacer-left-half" >
                    <MdArrowDropDown />
                </span>;
            } else {
                sortIcon = <span className="ct-icon-tiny spacer-left-half">
                    <MdArrowDropUp />
                </span>;
            }
        }

        return (
            <th
                role="columnheader"
                className={`listView-column ${this.props.columnClass} ${isSortable ? 'is-clickable' : ''} ${isActiveColumn ? 'is-active' : ''}`}
                onClick={this.handleSelected}
            >
                {this.props.label}
                {sortIcon}
            </th>
        );
    }
}

ListViewColumn.propTypes = {
    activeColumn: PropTypes.string,
    column: PropTypes.string.isRequired,
    columnClass: PropTypes.string,
    label: PropTypes.string.isRequired,
    onSelected: PropTypes.func
};

export default ListViewColumn;