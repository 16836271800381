import React, {Component} from 'react';
import PriceLogCell from '../priceLog/price.log.cell';

class ForecastRow extends Component {

    constructor(props) {
        super(props);
    }


    render() {
        const {isComparative, forecast, hasComparative} = this.props;

        return (
            <tr className={`${isComparative ? 'comparative_forecast' : ''}`}>
                {!isComparative && <td rowSpan={hasComparative ? '2' : '1'}>{forecast.dayOfWeek}</td>}
                <td>{forecast.calendarDate}</td>
                {
                    forecast.prices.map((priceLog) => {
                        return (
                            <PriceLogCell
                                key={priceLog.id}
                                isComparative={isComparative}
                                priceLog={priceLog}
                            />
                        )
                    })
                }
                {
                    forecast.current_situation.rooms_available.map((room) => {
                        return (<td key={room.id}>{room.quantity_available_rooms}</td>)
                    })
                }
                <td>{forecast.current_situation.occupied_rooms}</td>
                <td>{forecast.current_situation.percent_occupation}%</td>
                <td>{forecast.current_situation.pickup}</td>
                <td>$ {forecast.current_situation.sales}</td>
                <td>$ {forecast.current_situation.ADR}</td>
                <td>$ {forecast.current_situation.RevPAR}</td>
                <td>{ forecast.estimation.occupied_rooms}</td>
                <td>{ forecast.estimation.percent_occupation} %</td>
                <td>{ forecast.estimation.difference}%</td>
                <td>$ {forecast.estimation.sales.toFixed(2)}</td>
                <td>$ {forecast.estimation.ADR}</td>
                <td>$ {forecast.estimation.RevPAR}</td>
                <td>31</td>
                <td>86%</td>
                <td>19%</td>
                <td>$ 4.000,00</td>
                <td>$ 129,03</td>
                <td>$ 111,11</td>
            </tr>
        )
    }
}

export default ForecastRow;