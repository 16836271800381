import React from 'react';
import ListViewColumn from './list.view.column';
import PropTypes from 'prop-types';
import './list.view.scss';

class ListViewHeading extends React.Component {
    render() {
        const columns = this.props.columns && this.props.columns.length > 0 ? this.props.columns.map((column) => {
                return <ListViewColumn key={column.column}
                                       column={column.column}
                                       label={column.name}
                                       columnClass={column.columnClass}
                                       isSortable={column.isSortable}
                                       activeColumn={this.props.activeColumn}
                                       sortDirection={this.props.sortDirection}
                                       onSelected={this.props.onColumnSelected}/>;
            }) : '';

        return (
            <tr role="row" className="listView-heading">
                {columns}
            </tr>
        );
    }
}

ListViewHeading.propTypes = {
    columns: PropTypes.array,
    activeColumn: PropTypes.string,
    sortDirection: PropTypes.oneOf(['asc', 'desc']),
    onColumnSelected: PropTypes.func,
    isSortable: PropTypes.bool
};

export default ListViewHeading;