export default {
    ACTIONS: {
        'CREATE': 'Create',
        'READ': 'Read',
        'UPDATE': 'Update',
        'DELETE': 'Delete'
    },
    SUBJECTS: {
        'USER': 'User',
        'HOTEL': 'Hotel',
        'SETTINGS': 'Settings'
    }
};