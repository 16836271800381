import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Table} from 'reactstrap';


class Reports extends Component{
    render(){
        return (
            <div>
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Reportes {this.props.current_hotel.name}</h1>
                        <Table striped>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Username</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <th scope="row">1</th>
                                <td>Maikel</td>
                                <td>Otto</td>
                                <td>@mdo</td>
                            </tr>
                            <tr>
                                <th scope="row">2</th>
                                <td>Agustin</td>
                                <td>Thornton</td>
                                <td>@fat</td>
                            </tr>
                            <tr>
                                <th scope="row">3</th>
                                <td>Diego</td>
                                <td>the Bird</td>
                                <td>@twitter</td>
                            </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>

            </div>
        )
    }
};

const mapStateToProps = (state) => ({
    current_hotel: state.current_hotel.hotel_info
});

export default connect(mapStateToProps)(Reports);