import React, {Component} from 'react';
import {Button, Alert, Card, CardBody, CardTitle, FormGroup, Label, Input} from 'reactstrap';
import moment from 'moment';
import {connect} from 'react-redux';
import {startProcessCSVFile} from '../../actions/upload.csv.file';
import CSVFileReader from './csv.file.reader';

class LoadForecast extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            messageResponse: {},
            isMassUpload: false,
            loadingData: false,
            processingFile: false
        };

        this.handleMassUpload = this.handleMassUpload.bind(this);
    }

    parsingForecast = (dataMatrix, snapshotDate) => {
        let dataResult = [];
        const room_types = this.props.current_hotel.room_types;
        dataMatrix.forEach((it, index) => {
            for (let i = 0; i < room_types.length; i++) {
                let forecast = {};
                forecast['room_type_id'] = room_types[i].id;
                forecast['calendar_date'] = moment(it[0], 'DD/MM/YYYY').format('YYYY-MM-DD');
                forecast['snapshot_date'] = snapshotDate;
                forecast['days_in_advance'] = index;
                forecast['quantity_available_rooms'] = it[i + 1];
                forecast['amount'] = null;
                dataResult.push(forecast);
            }

        });
        this.setState(() => ({
            data: dataResult
        }));
    };

    prepareResult = (dataMatrix) => {
        let dataResult = [];
        dataMatrix.forEach((it) => {
            let forecast = {};
            forecast['room_type_id'] = it[1];
            forecast['calendar_date'] = moment(it[2]).format('YYYY-MM-DD');
            forecast['snapshot_date'] = moment(it[3]).format('YYYY-MM-DD');
            forecast['days_in_advance'] = it[4];
            forecast['quantity_available_rooms'] = it[5];
            forecast['amount'] = null;
            dataResult.push(forecast);

        });
        this.setState(() => ({
            data: dataResult,
            loadingData: false
        }));
    };

    //Carga diaria, datos de sistema boliviano
    handleDailyUpload = (data, filename) => {
        const snapshotDate = moment(filename.split('.')[0].split('_').slice(1, 4).reverse().join('-')).add(1, 'days').format('YYYY-MM-DD');
        let dataMatrix = data.filter(it => it.length > 1 && it[0] !== "");
        this.parsingForecast(dataMatrix, snapshotDate);
    };


    //Carga masiva de datos, preparados previamente
    handleMassUpload(data) {
        this.setState(() => ({
            loadingData: true
        }));

        // Necesario para que pueda ser el render correctamente cuando loadingDate es seteado. Carga masiva es lenta.
        setTimeout(() => {
            let dataMatrix = data.filter((it, index) => index > 0 && it.length > 1);
            this.prepareResult(dataMatrix);
        }, 1)
    };

    onProcessFile = () => {
        const {data} = this.state;
        const dataSize = data.length;
        const items = 5000;
        let counter = 0;
        let callToServer = 0;

        if(this.state.isMassUpload){
            this.setState(() => ({
                processingFile: true
            }));
        }

        while (counter < dataSize) {
            const dataSet = data.filter((it, index) => index >= counter && index < counter + items);
            startProcessCSVFile(dataSet, this.state.isMassUpload).then((response) => {
                callToServer ++;

                if(callToServer === Math.ceil(dataSize/items)){
                    this.setState(() => ({
                        messageResponse: response,
                        processingFile: false
                    }));
                    setTimeout(() => {
                        this.setState(() => ({
                            messageResponse: {}
                        }))
                    }, 3000)
                }
            });

            counter += 5000;

        }

        this.setState(() => ({
            data: []
        }));
    };

    setMassUpload = () => {
        this.setState((prevState) => ({
            isMassUpload: !prevState.isMassUpload
        }))
    };

    handleError = (error) => {
        console.log("Error", error)
    };


    render() {
        const {data, messageResponse, isMassUpload, processingFile, loadingData} = this.state;

        return (
            <Card className="mt-3">
                <CardBody>
                    <CardTitle>Carga de datos: <b>Forecast</b></CardTitle>
                    {!processingFile && messageResponse.status &&
                    <div className={`upload-file-message ${messageResponse.status}`}>
                        <Alert color={`${messageResponse.status == 'has-success' ? 'success' : 'danger'}`}>
                            {messageResponse.message}
                        </Alert>
                    </div>}

                    <FormGroup check>
                        <Label check>
                            <Input type="checkbox" onChange={this.setMassUpload}/>{' '}
                            Es una carga masiva?
                        </Label>
                    </FormGroup>

                    { processingFile &&
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6">
                            <div className="mt-2 mb-0 alert alert-info fade show" role="alert">
                                Por favor espere, estamos procesando el archivo...
                            </div>
                        </div>
                    </div>
                    }
                    { loadingData &&
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6">
                            <div className="mt-2 mb-0 alert alert-info fade show" role="alert">
                                Por favor espere, estamos cargando la información...
                            </div>
                        </div>
                    </div>
                    }
                    <CSVFileReader
                        label="Select CSV File "
                        onDataLoaded={isMassUpload ? this.handleMassUpload : this.handleDailyUpload}
                        onError={this.handleError}
                        inputId="csv-input-text"
                    />

                    {
                        data.length > 0 &&
                        <Button color="success" onClick={this.onProcessFile}>
                            Process File
                        </Button>
                    }
                </CardBody>
            </Card>
        )
    }
}

const mapStateToProps = (state) => ({
    current_hotel: state.current_hotel.hotel_info
});

export default connect(mapStateToProps)(LoadForecast);
