import React, {Component} from 'react';
import Autosuggest from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import {MdVerticalAlignBottom} from 'react-icons/md';
import {Button} from 'reactstrap';


class Autocomplete extends React.Component {
    constructor() {
        super();

        this.state = {
            value: '',
            valueSuggested: {},
            suggestions: []
        };
    }

    escapeRegexCharacters = (str) => {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    getSuggestions = (value) => {
        const escapedValue = this.escapeRegexCharacters(value.trim());

        if (escapedValue === '') {
            return [];
        }

        const regex = new RegExp('\\b' + escapedValue, 'i');

        return this.props.users.filter(user => regex.test(this.getSuggestionValue(user)));
    }

    getSuggestionValue = (suggestion) => {
        return suggestion.email;
    }

    renderSuggestion = (suggestion, {query}) => {
        const suggestionText = suggestion.email;
        const matches = AutosuggestHighlightMatch(suggestionText, query);
        const parts = AutosuggestHighlightParse(suggestionText, matches);

        return (
            <span className="suggestion-content">
              <span className="name">
                {
                    parts.map((part, index) => {
                        const className = part.highlight ? 'highlight' : null;

                        return (
                            <span className={className} key={index}>{part.text}</span>
                        );
                    })
                }
              </span>
            </span>
        );
    }

    onChange = (event, {newValue, method}) => {
        this.setState({
            value: newValue
        });
    };

    onSuggestionsFetchRequested = ({value}) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    handleSuggestionSelected = (event, {suggestion}) => {
        if(suggestion){
            this.setState(() => ({
                valueSuggested: suggestion
            }))
        }
    }

    handleAssignUser = () => {
        if(this.state.valueSuggested.email){
            this.props.onAssignUser(this.state.valueSuggested);
        }
        this.setState(() => ({
            value: '',
            valueSuggested: {}
        }))
    };

    render() {
        const {value, suggestions} = this.state;
        const inputProps = {
            placeholder: "Buscar usuario por correo electrónico",
            value,
            onChange: this.onChange
        };

        return (
            <div className="c-flex mb-3">
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    onSuggestionSelected={this.handleSuggestionSelected}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    inputProps={inputProps}/>
                <div>
                    <Button color="success" onClick={this.handleAssignUser}>
                        Add
                    </Button>
                </div>
            </div>

        );
    }
}

export default Autocomplete;