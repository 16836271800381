import axios from 'axios';
import * as AppConfig from '../config/app-base-config.json';
import {SET_HOTELS, UPDATE_HOTEL, ADD_HOTEL, EDIT_HOTEL, REMOVE_HOTEL, REQUEST_HOTELS, CLEAR_MESSAGE} from './types';

const setHotels = (hotels) => ({
   type:  SET_HOTELS,
    hotels
});

export const updateHotel = (hotel) => ({
    type:  UPDATE_HOTEL,
    hotel
});

const setAddHotel = (hotel) => ({
    type: ADD_HOTEL,
    hotel
})

const setEditHotel = (id, updates) => ({
    type: EDIT_HOTEL,
    id,
    updates
})

const setRemoveHotel = (id) => ({
    type: REMOVE_HOTEL,
    id,
})

const requestHotels = () => ({
    type: REQUEST_HOTELS
})

const clearMessage = () => ({
    type: CLEAR_MESSAGE
});

export const startSetHotels = () => {
    return (dispatch) => {
        dispatch(requestHotels());
        return axios.get(AppConfig.API_BASE_URL.concat('/hotels')).then((response) => {
            const hotels = response.data.hotels;
            return dispatch(setHotels(hotels));
        })
    }
}

const headers = {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
};


export const addHotel = (hotelInfo) => {
    return (dispatch) => {

        return axios.post(AppConfig.API_BASE_URL.concat('/hotels'), hotelInfo, headers).then((response) => {
            const hotel = response.data.hotel;
            dispatch(setAddHotel(hotel));
            setTimeout(() => dispatch(clearMessage()), 3000);
        })
    }
};


export const editHotel = (id, updates) => {
    return (dispatch) => {

        axios.put(AppConfig.API_BASE_URL.concat(`/hotels/${id}`), updates, headers).then((response) => {
            dispatch(setEditHotel(id, response.data.hotel));
            setTimeout(() => dispatch(clearMessage()), 3000);
        })
    }
};


export const removeHotel = (id) => {
    return (dispatch) => {

        axios.delete(AppConfig.API_BASE_URL.concat(`/hotels/${id}`),  headers).then((response) => {
            dispatch(setRemoveHotel(id));
            setTimeout(() => dispatch(clearMessage()), 3000);
        })
    }
};

