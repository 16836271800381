import React, {Component} from 'react';
import ForecastRow from './forecast.row';

class ForecastList extends Component {
    handlePriceChange = (event, priceLogId) => {
        event.preventDefault();
       //console.log("****", event.target.value, priceLogId);
    }

    render() {
        const {forecastList} = this.props;
        return (
            <tbody>
            {
                forecastList.map((forecast, index) => {
                    return (
                        <ForecastRow
                            key={index}
                            forecast={forecast}
                            isComparative={forecast.isComparative}
                            hasComparative = { forecastList[index + 1] && forecastList[index + 1].isComparative ? true : false }
                            onPriceChange={this.handlePriceChange}
                        />
                    )
                })
            }
            </tbody>
        )

    }
}

export default ForecastList;