import React, {Component} from 'react'
import {Field, reduxForm} from 'redux-form';
import {FormGroup, Label, Input, Button, ModalBody, ModalFooter} from 'reactstrap';
import TextField from '../form/text.field';
import SelectField from '../form/select.field';
import {combineValidators, required, isEmail, isPassword} from '../../utils/validation';
import {connect} from 'react-redux';

const validate = (values) => {
    const errors = {};

    errors.name = combineValidators('Nombre', values.name, required);
    errors.email = combineValidators('Correo electrónico', values.email, isEmail);
    errors.password = combineValidators('Password', values.password, required);
    errors.confirmPassword = combineValidators(values.password, values.confirmPassword, isPassword);
    errors.role_id = combineValidators('Rol', values.role_id, required);

    return errors;
};

class UserForm extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {handleSubmit, roles, toggleModal} = this.props;
        const options = roles.map((role) => {
            return {
                text: role.name,
                value: role.id
            }
        });

        return (
            <form onSubmit={handleSubmit}>
                <div>
                    <ModalBody>
                        <FormGroup>
                            <Field
                                id="userName"
                                name="name"
                                required={true}
                                component={TextField}
                                type="text"
                                placeholder="Nombre"
                                label="Nombre"
                                autocomplete="name"
                                autofocus
                            />
                        </FormGroup>
                        <FormGroup>
                            <Field
                                id="userEmail"
                                name="email"
                                required={true}
                                component={TextField}
                                type="email"
                                placeholder="Correo electrónico"
                                label="Correo electrónico"
                                autocomplete="email"
                            />
                        </FormGroup>
                        { !this.props.userId &&
                        <FormGroup>
                            <Field
                                id="userPassword"
                                name="password"
                                required={true}
                                component={TextField}
                                type="password"
                                placeholder="Contraseña"
                                label="Contraseña"
                                autocomplete="new-password"
                            />
                        </FormGroup>
                        }
                        { !this.props.userId &&
                        <FormGroup>
                            <Field
                                id="userPasswordConfirmation"
                                name="confirmPassword"
                                required={true}
                                component={TextField}
                                type="password"
                                placeholder="Repita la contraseña"
                                label="Repita la contraseña"
                                autocomplete="new-password"
                            />
                        </FormGroup>
                        }
                        <FormGroup>
                            <Field
                                name="role_id"
                                required={true}
                                component={SelectField}
                                label="Seleccione un rol"
                                options={options}
                            >
                            </Field>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                        <Button color="primary" type="submit">Guardar</Button>
                    </ModalFooter>
                </div>
            </form>
        )
    }
}

const mapStateToProps = (state,ownProps) => {
    const user = state.users.data.find((user) => user.id == ownProps.userId);
    const values = user ? {name: user.name, email: user.email, role_id: user.role.id} : {};

    return {
        initialValues: values
    }
}


export default (connect(mapStateToProps)(reduxForm({
    form: 'userForm',
    validate,
    enableReinitialize: true
})(UserForm)));
