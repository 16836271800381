import React, {Component} from 'react'
import {Field, reduxForm} from 'redux-form';
import {FormGroup, Label, Input, Button, ModalBody, ModalFooter} from 'reactstrap';
import TextField from '../form/text.field';
import SelectField from '../form/select.field';
import {combineValidators, required, isEmail, isPassword} from '../../utils/validation';
import {connect} from 'react-redux';

const validate = (values) => {
    const errors = {};

    errors.name = combineValidators('Nombre', values.name, required);
    errors.hotel_id = combineValidators('Hotel', values.hotel_id, required);
    errors.quantity = combineValidators('Cantidad', values.quantity, required);
    errors.max_quantity_guest = combineValidators('Cantidad max de huesped', values.max_quantity_guest, required);
    errors.short_name = combineValidators('Nombre corto', values.short_name, required);

    return errors;
};

class RoomForm extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {handleSubmit, hotels, toggleModal} = this.props;
        const options = hotels.data.map((hotel) => {
            return {
                text: hotel.name,
                value: hotel.id
            }
        });

        return (
            <form onSubmit={handleSubmit}>
                <div>
                    <ModalBody>
                        <FormGroup>
                            <Field
                                id="roomName"
                                name="name"
                                required={true}
                                component={TextField}
                                type="text"
                                placeholder="Nombre"
                                label="Nombre"
                                autocomplete="name"
                                autofocus
                            />
                        </FormGroup>
                        <FormGroup>
                            <Field
                                name="hotel_id"
                                required={true}
                                component={SelectField}
                                label="Seleccione un hotel"
                                options={options}
                            >
                            </Field>
                        </FormGroup>
                        <FormGroup>
                            <Field
                                id="shortName"
                                name="short_name"
                                required={true}
                                component={TextField}
                                type="text"
                                placeholder="Nombre corto Ej. LKS"
                                label="Nombre corto"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Field
                                id="quantityRooms"
                                name="quantity"
                                required={true}
                                component={TextField}
                                type="number"
                                placeholder="Cantidad de habitaciones"
                                label="Cantidad de habitaciones"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Field
                                id="maxquantityGuest"
                                name="max_quantity_guest"
                                required={true}
                                component={TextField}
                                type="number"
                                placeholder="Cantidad max de huesped"
                                label="Cantidad max de huesped"
                            />
                        </FormGroup>

                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                        <Button color="primary" type="submit">Guardar</Button>
                    </ModalFooter>
                </div>
            </form>
        )
    }
}

const mapStateToProps = (state,ownProps) => {
    const room = state.rooms.data.find((room) => room.id == ownProps.roomId);
    const values = room ? {...room } : {};

    return {
        initialValues: values
    }
}


export default (connect(mapStateToProps)(reduxForm({
    form: 'roomForm',
    validate,
    enableReinitialize: true
})(RoomForm)));
