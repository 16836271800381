import {SET_FORECAST_FAILURE, SET_FORECAST_REQUEST, SET_FORECAST_SUCCESS, UPDATE_PRICE_LOG, PRICES_LOG_SAVED} from '../actions/types';

const forecastDefaultState = {
    isLoadingForecast: false,
    data: [],
    errorMessage: '',
    pricesToModify: []
}

export default (state = forecastDefaultState, action) => {
    switch (action.type) {
        case SET_FORECAST_REQUEST:
            return {
                ...state,
                isLoadingForecast: true
            }
        case SET_FORECAST_SUCCESS:
            return {
                ...state,
                isLoadingForecast: false,
                data: action.data
            }
        case SET_FORECAST_FAILURE:
            return {
                ...state,
                isLoadingForecast: false,
                data: [],
                errorMessage: action.errorMessage
            }
        case UPDATE_PRICE_LOG:
            let priceLogIndex = state.pricesToModify.findIndex((priceLog) => priceLog.id === action.priceLog.id);
            if (priceLogIndex !== -1) {
                state.pricesToModify[priceLogIndex] = action.priceLog;
                return state;
            } else {
                return {
                    ...state,
                    pricesToModify: [...state.pricesToModify, action.priceLog]
                }
            }
        case PRICES_LOG_SAVED:
                return {
                    ...state,
                    pricesToModify: []
                }
        default:
            return state;
    }
}