import React, {Component} from 'react';
import {Button} from 'reactstrap';
import {connect} from 'react-redux';
import {startSavePricesLog} from '../../actions/forecast';

class ForecastHeading extends Component {
    constructor(props) {
        super(props);
    }

    handleClick = () => {
        this.props.savePricesLog(this.props.pricesToModify);
    }

    render() {
        const {roomTypes, pricesToModify} = this.props;
        return (
            <thead>
            <tr className="forecast-heading">
                <th colSpan="2" rowSpan="2" className="bg-diagonal-lines">
                    <Button
                        color="success"
                        onClick={this.handleClick}
                        disabled={ pricesToModify.length > 0 ? false : true}
                    >
                        Guardar
                    </Button>
                </th>
                <th colSpan={roomTypes.length} rowSpan="2" className="text-center">Precios</th>
                <th colSpan={ roomTypes.length + 6} className="text-center">Situación Actual</th>
                <th colSpan="6" className="text-center">Estimado/Cierre</th>
                <th colSpan="6" className="text-center">Presupuesto</th>
            </tr>
            <tr className="forecast-heading">
                <th colSpan={roomTypes.length} className="text-center">Habitaciones disponibles</th>
                <th colSpan="6"></th>
                <th colSpan="6"></th>
                <th colSpan="6"></th>
            </tr>
            <tr>
                <th>Día</th>
                <th>Fecha</th>
                {
                    [...Array(2)].map(() => {
                        return roomTypes.map((room) =>
                            (<th key={room.id}>{room.short_name ? room.short_name : room.name }</th>)
                        )
                    })
                }
                <th>Hab Ocupadas</th>
                <th>% Ocupacion</th>
                <th>PickUp</th>
                <th>Venta</th>
                <th>ADR</th>
                <th>RevPar</th>
                <th>Hab Ocupadas</th>
                <th>% Ocupacion</th>
                <th>Diferencia</th>
                <th>Venta</th>
                <th>ADR</th>
                <th>RevPar</th>
                <th>Hab Ocupadas</th>
                <th>% Ocupacion</th>
                <th>Diferencia</th>
                <th>Venta</th>
                <th>ADR</th>
                <th>RevPar</th>
            </tr>
            </thead>
        )
    }

}

const mapStateToProps = (state) => ({
    pricesToModify: state.forecast.pricesToModify
});

const mapDispatchToProps = (dispatch) => ({
    savePricesLog: (pricesToModify) => dispatch(startSavePricesLog(pricesToModify))
});


export default connect(mapStateToProps, mapDispatchToProps)(ForecastHeading);