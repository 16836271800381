import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Table} from 'reactstrap';
import {SingleDatePicker, DateRangePicker} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import {START_DATE_TEXT, END_DATE_TEXT} from '../../utils/constants';
import 'react-dates/initialize';
import YearSelector from '../selectors/year.selector';
import {FormGroup, Label} from 'reactstrap';
import moment from 'moment';
import ForecastHeading from './forecast.heading';
import { LoadingShading } from '../loadingData/loading.shading';
import {startSetForecast} from '../../actions/forecast';
import isInclusivelyAfterDay from '../../utils/isInclusivelyAfterDay';
import ForecastList from './forecast.list';


const defaultProps = {
    initialStartDate: moment(),
    date: moment(),
    focused: true,
    initialEndDate: moment().add(15, 'days'),
    yearSelected: moment().subtract(1, 'year').format('YYYY'),
    isOutsideRange: day => !isInclusivelyAfterDay(day, moment()),
};


class ForecastPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            startDate: props.initialStartDate,
            endDate: props.initialEndDate,
            date: props.date,
            focused: null,
            startDatePlaceholderText: START_DATE_TEXT,
            endDatePlaceholderText: END_DATE_TEXT,
            yearSelected: 0,
            focusedInput: null,
            isOutsideRange: props.isOutsideRange,
            currentHotelId: props.current_hotel.id
        };

        this.onDatesChange = this.onDatesChange.bind(this);
        this.onFocusChange = this.onFocusChange.bind(this);
    }

    componentDidMount() {
        const {date, startDate, endDate, yearSelected} = this.state;
        this.reloadForecast(date, startDate, endDate, yearSelected);
    }

    componentDidUpdate() {
        if(this.state.currentHotelId !== this.props.current_hotel.id){
            this.setState(() => ({
                currentHotelId: this.props.current_hotel.id
            }));
            const {date, startDate, endDate, yearSelected} = this.state;
            this.reloadForecast(date, startDate, endDate, yearSelected);
        }
    }

    onDatesChange({startDate, endDate}) {
        if (startDate && endDate) {
            this.reloadForecast(this.state.date, startDate, endDate, this.state.yearSelected);
        }
        this.setState({
            startDate: startDate,
            endDate: endDate,
        });

    }

    onFocusChange(focusedInput) {
        this.setState({focusedInput});
    }

    handleClose = () => {
        //console.log("Close Panel")
    }

    onYearComparisonChange = (yearSelected) => {
        this.setState(() => ({
            yearSelected
        }))
        this.reloadForecast(this.state.date, this.state.startDate, this.state.endDate, yearSelected);
    }

    reloadForecast = (snapshotDate, startDate, endDate, yearSelected) => {
        const info = {
            hotelId: this.props.current_hotel.id,
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD'),
            comparisonYear: yearSelected,
            snapshotDate: snapshotDate.format('YYYY-MM-DD')
        }
        this.props.startSetForecast(info);
    }

    handleDateChange = (date) => {
        this.setState(() => ({
            date: date,
            startDate: date,
            endDate: moment(date).add(15, 'days')
        }));

        this.reloadForecast(date, date, moment(date).add(15, 'days'), this.state.yearSelected);
    }


    render() {
        const {isLoadingForecast, data, errorMessage} = this.props.forecast;
        const smallDevice = window.matchMedia('(max-width: 400px)').matches;
        const orientation = smallDevice ? 'VERTICAL_ORIENTATION' : 'HORIZONTAL_ORIENTATION';
        return (
            <div>
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h2>Pronóstico {this.props.current_hotel.name}</h2>
                    </div>
                </div>

                <div className="row forecast-header">
                    <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9 text-left forecast__dates">
                        <FormGroup className="date-selector forecast-date">
                            <Label>Snapshot date</Label>
                            <SingleDatePicker
                                date={this.state.date} // momentPropTypes.momentObj or null
                                onDateChange={this.handleDateChange} // PropTypes.func.isRequired
                                focused={this.state.focused} // PropTypes.bool
                                onFocusChange={({focused}) => this.setState({focused})} // PropTypes.func.isRequired
                                id="snapshotDate" // PropTypes.string.isRequired,
                                displayFormat="DD/MM/YYYY"
                                isOutsideRange={() => false}
                                numberOfMonths={1}
                                isDayBlocked={() => false}
                                enableOutsideDays={false}
                            />

                        </FormGroup>
                        <FormGroup className="date-selector forecast-range-date">
                            <Label>Calendar dates</Label>
                            <DateRangePicker
                                startDate={this.state.startDate}
                                startDateId="forecast_start_date"
                                endDate={this.state.endDate}
                                endDateId="forecast_end_date"
                                onDatesChange={this.onDatesChange}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={this.onFocusChange}
                                startDatePlaceholderText={this.state.startDatePlaceholderText}
                                endDatePlaceholderText={this.state.endDatePlaceholderText}
                                displayFormat="DD/MM/YYYY"
                                numberOfMonths={ smallDevice ? 1 : 2}
                                onClose={this.handleClose}
                                isOutsideRange={() => false}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3  text-right">
                        <YearSelector onYearChanged={this.onYearComparisonChange}/>
                    </div>
                </div>

                <div className="row forecast-content">
                    <div className="table-area">
                        <Table responsive bordered className="table-fixed">
                            <ForecastHeading roomTypes={this.props.current_hotel.room_types}/>
                            <ForecastList forecastList={data}/>
                        </Table>
                        { isLoadingForecast && <div className="modal-backdrop fade show bg-White"></div>}
                        {isLoadingForecast && <LoadingShading />}
                    </div>
                </div>
            </div>
        )
    }
}
;

const mapStateToProps = (state) => ({
    current_hotel: state.current_hotel.hotel_info,
    forecast: state.forecast
});

const mapDispatchToProps = (dispatch) => ({
    startSetForecast: (info) => dispatch(startSetForecast(info))
});

ForecastPage.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(ForecastPage);