import React, {Component} from 'react';
import EditUserButton from './edit.user.button';
import RemoveUserButton from './remove.user.button';
import ChangePasswordButton from './change.password.button';

class UserItem extends Component {
    constructor(props) {
        super(props);
        this.state = {openUserModal: false}
    }

    render() {
        const {name, email, rol, isCurrentUser, userId} = this.props;
        return (
            <tr role="row" className="list-item">
                <td role="cell" className="py-2 align-middle">{name}</td>
                <td role="cell" className="py-2 align-middle">{email}</td>
                <td role="cell" className="py-2 align-middle">{rol}</td>
                <td role="cell" className="action-items py-2 align-middle">
                    <div className="small-buttons">
                        <ChangePasswordButton userId={userId} />
                        <EditUserButton userId={userId}/>
                        <RemoveUserButton userId={userId} isCurrentUser={isCurrentUser}/>
                    </div>
                </td>
            </tr>
        )
    }
}

export default UserItem;