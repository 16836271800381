import React from 'react';
import {Label, Form, FormGroup, Input} from 'reactstrap';


const SwitchHotel = ({hotels, currentHotel, onHandleHotelChange}) => (
    <div>
        <Form>
            <FormGroup>
                <Label for="hotels">Hoteles disponibles</Label>
                <Input type="select" name="select" id="hotels" value={currentHotel.id} onChange={onHandleHotelChange}>
                    {
                        hotels.map((hotel) => {
                            return(<option key={hotel.id} value={hotel.id}>{hotel.name}</option>)
                        })
                    }
                </Input>
            </FormGroup>
        </Form>
    </div>
);

export default SwitchHotel;