import React, {Component} from 'react';
import LoginForm from './login.form';
import {connect} from 'react-redux';
import { startLogin, startClearErrorMessage} from '../../actions/auth';
import LoadingDialog from '../../components/loadingData/loading.dialog';

class LoginPage extends Component {

    render() {
        const {error_message, logging} = this.props.auth;
        return (
            <div className="box-layout">
                <div className="box-layout__box">
                    <div className="box-layout__title">
                        <h1>Login</h1>
                        <hr/>
                    </div>
                    <LoginForm
                        onSubmit={this.props.startLogin}
                        removeErrorMessage={this.props.clearErrorMessage}
                        errorMessage={error_message}
                    />
                </div>
                {
                    logging &&
                    <LoadingDialog message="Logging In..." />
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
});


const mapDispatchToProps = (dispatch) => ({
    startLogin: (email, password) => dispatch(startLogin(email, password)),
    clearErrorMessage: () => dispatch(startClearErrorMessage())
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);