import {SET_HOTELS, EDIT_HOTEL, ADD_HOTEL, REMOVE_HOTEL, REQUEST_HOTELS, CLEAR_MESSAGE} from '../actions/types';
import {MESSAGE_TYPES} from '../utils/constants';

const hotelsDefaultState = {
    isLoadingHotels: false,
    data: [],
    message: {type: "", text: ""}
}


export default (state = hotelsDefaultState, action) => {
    switch (action.type) {
        case REQUEST_HOTELS:
            return {
                ...state,
                isLoadingHotels: true
            };
        case SET_HOTELS:
            return {
                ...state,
                data: action.hotels,
                isLoadingHotels: false
            }
        case ADD_HOTEL:
            return {
                ...state,
                data: [...state.data, action.hotel],
                message: {type: MESSAGE_TYPES.success, text: "Hotel adicionado correctamente"}
            };
        case EDIT_HOTEL:
            const dataUpdated = state.data.map((hotel) => {
                if (hotel.id === action.id) {
                    return {
                        ...hotel,
                        ...action.updates
                    };
                } else {
                    return hotel;
                }
            });
            return {
                ...state,
                data: dataUpdated,
                message: {type: MESSAGE_TYPES.success, text: "Hotel fue editado correctamente"}
            }
        case REMOVE_HOTEL:
            const newData = state.data.filter(({id}) => id !== action.id);
            return {
                ...state,
                data: newData,
                message: {type: MESSAGE_TYPES.success, text: "Hotel fue eliminado correctamente"}
            }
        case CLEAR_MESSAGE:
            return {
                ...state,
                message: {type: "", text: ""}
            }
        default:
            return state;
    }
}