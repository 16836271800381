import React, {Component} from 'react'
import {Field, reduxForm} from 'redux-form';
import {FormGroup, Label, Input, Button, ModalBody, ModalFooter} from 'reactstrap';
import TextField from '../form/text.field';
import SelectField from '../form/select.field';
import {combineValidators, required, isEmail, isPassword} from '../../utils/validation';
import {connect} from 'react-redux';

const validate = (values) => {
    const errors = {};
    errors.name = combineValidators('Nombre', values.name, required);
    errors.city = combineValidators('City', values.city, required);
    errors.quantity_rooms = combineValidators('Cantidad de habitaciones', values.quantity_rooms, required);
    return errors;
};

class HotelForm extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {handleSubmit, toggleModal} = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <div>
                    <ModalBody>
                        <FormGroup>
                            <Field
                                id="userName"
                                name="name"
                                required={true}
                                component={TextField}
                                type="text"
                                placeholder="Nombre"
                                label="Nombre"
                                autocomplete="name"
                                autofocus
                            />
                        </FormGroup>
                        <FormGroup>
                            <Field
                                id="city"
                                name="city"
                                required={true}
                                component={TextField}
                                type="text"
                                placeholder="Ciudad"
                                label="Ciudad"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Field
                                id="quantityRooms"
                                name="quantity_rooms"
                                required={true}
                                component={TextField}
                                type="text"
                                placeholder="Cantidad de habitaciones"
                                label="Cantidad de habitaciones"
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                        <Button color="primary" type="submit">Guardar</Button>
                    </ModalFooter>
                </div>
            </form>
        )
    }
}

const mapStateToProps = (state,ownProps) => {
    const hotel = state.hotels.data.find((hotel) => hotel.id == ownProps.hotelId);
    const values = hotel ? {name: hotel.name, city: hotel.city, quantity_rooms: hotel.quantity_rooms} : {}

    return {
        initialValues: values
    }
}


export default (connect(mapStateToProps)(reduxForm({
    form: 'hotelForm',
    validate,
    enableReinitialize: true
})(HotelForm)));
