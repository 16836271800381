import {SET_CURRENT_HOTEL} from './types';
import axios from 'axios';
import * as AppConfig from '../config/app-base-config.json';
import {updateHotel} from './hotels';

export const setCurrentHotel = (current_hotel) => ({
    type: SET_CURRENT_HOTEL,
    current_hotel
});

export const clearCurrentHotel = () => ({
    type: SET_CURRENT_HOTEL,
    current_hotel: {}
});

export const addUsersToHotel = (hotelId, userIds) => {
    return (dispatch) => {
        const headers = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        axios.post(AppConfig.API_BASE_URL.concat(`/hotels/${hotelId}/users`), {userIds: userIds}, headers).then((response) => {
            const current_hotel = response.data.current_hotel;
            localStorage.current_hotel = JSON.stringify(current_hotel);
            dispatch(setCurrentHotel(current_hotel));
            dispatch(updateHotel(current_hotel));
        })
    }

}


export const removeHotelUser = (userId, hotelId) => {
    return (dispatch) => {
        const headers = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        axios.post(AppConfig.API_BASE_URL.concat(`/hotels/${hotelId}/users/${userId}`), headers).then((response) => {
            const current_hotel = response.data.current_hotel;
            localStorage.current_hotel = JSON.stringify(current_hotel);
            dispatch(setCurrentHotel(current_hotel));
            dispatch(updateHotel(current_hotel));
        })
    }

}
