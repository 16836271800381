import axios from 'axios';
import * as AppConfig from '../config/app-base-config.json';

export const startProcessCSVFile = (data, isMassUpload) => {
   return axios.post(AppConfig.API_BASE_URL.concat("/csv/process-file"), {'data': data, isMassUpload: isMassUpload}).then((response) => {
       return response.data;
   })
};

export const loadCSVCalendarDays = (data) => {
    return axios.post(AppConfig.API_BASE_URL.concat("/csv/calendar-days/process-file"), {'data': data}).then((response) => {
        return response.data;
    })
};

export const loadCSVOccupancies = (data) => {
    return axios.post(AppConfig.API_BASE_URL.concat("/csv/occupancies/process-file"), {'data': data}).then((response) => {
        return response.data;
    })
};

export const loadCSVPriceLogs = (data) => {
    return axios.post(AppConfig.API_BASE_URL.concat("/csv/price-logs/process-file"), {'data': data}).then((response) => {
        return response.data;
    })
};

