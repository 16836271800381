import React from 'react';
import PropTypes from 'prop-types';
import './no.results.found.scss';

const NoResultsFound = ({message}) => (
    <div className="e-flex-container">
        <div className="e-noResultsFound">
            {message}
        </div>
    </div>
);

NoResultsFound.propTypes = {
    message: PropTypes.string
}

NoResultsFound.defaultProps = {
    message: 'No results found'
}

export default NoResultsFound;