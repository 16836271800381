import React, {Component} from 'react';
import {connect} from 'react-redux';
import {removeHotelUser} from '../../actions/current.hotel';
import {MdDelete} from 'react-icons/md';
import {Button, Modal, ModalHeader, ModalFooter, ModalBody, Tooltip} from 'reactstrap';


class RemoveHotelUserButton extends Component {
    constructor(props) {
        super(props);
        this.state = {openModal: false, tooltipOpen: false}
    }

    toggleModal = (event) => {
        event.preventDefault();
        this.setState(() => ({
            openModal: !this.state.openModal,
        }))
    };

    toggle = () => {
        this.setState((prevState) => ({
            tooltipOpen: !prevState.tooltipOpen
        }))
    }

    removeHotelUser = () => {
        this.props.removeHotelUser(this.props.userId, this.props.currentHotelId);
        this.setState(() => ({
            openModal: false
        }))
    }

    render() {
        return (
            <div>
                <Button
                    id={"deleteHotelUserButton" + this.props.userId}
                    color="danger"
                    onClick={ this.toggleModal }
                    className="ml-3"
                >
                    <MdDelete/>
                </Button>
                {
                    this.props.hotelId !== this.props.currentHotelId &&
                    < Tooltip
                        placement="bottom"
                        isOpen={this.state.tooltipOpen}
                        target={"deleteHotelUserButton" + this.props.userId}
                        toggle={this.toggle}
                        trigger="hover"
                        delay={{show: 10, hide: 50}}
                    >
                        Desvincular usuario del hotel
                    </Tooltip>
                }
                <Modal isOpen={this.state.openModal} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>
                        Desvincular usuario del hotel
                    </ModalHeader>
                    <ModalBody>
                        ¿Está seguro que desea desvincular este usuario del hotel?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.removeHotelUser}>Estoy seguro</Button>
                        <Button color="secondary" onClick={this.toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currentHotelId: state.current_hotel.hotel_info.id
})

const mapDispatchToProps = (dispatch) => ({
    removeHotelUser: (userId, hotelId) => dispatch(removeHotelUser(userId, hotelId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoveHotelUserButton);