import React, {Component} from 'react';
import {DebounceInput} from 'react-debounce-input';
import { connect } from 'react-redux';
import { startAddPriceLog } from '../../actions/forecast';
import './price.log.cell.scss';

class PriceLogCell extends Component {
    constructor(props) {
        super(props);
        this.state = {isEditing: false, value: this.props.priceLog.price}
    }

    handleClick = () => {
        this.setState((prevState) => ({
            isEditing: true
        }))
    };

    handlePriceChange = (event) => {
        const newPriceValue = event.target.value;
        this.setState(() => ({
            value: newPriceValue
        }));
        this.props.addPriceLog({id: this.props.priceLog.id, price: newPriceValue})
    };

    handleOnBlur = () => {
        this.setState(() => ({
            isEditing: false
        }))
    };


    render() {
        const {isComparative, priceLog} = this.props;
        return !isComparative ? <td onClick={this.handleClick}>
                {!this.state.isEditing && `$ ${this.state.value}`}
                { this.state.isEditing &&
                <div className="price-log">
                    <p className="price-log__simbol">$</p>
                    <DebounceInput
                        type="number"
                        inputMode="numeric"
                        debounceTimeout={500}
                        className="price-log__input"
                        value={this.state.value}
                        onChange={this.handlePriceChange}
                        onBlur={this.handleOnBlur}
                    />
                </div>
                }
            </td> :
            <td>
                $ {priceLog.price}
            </td>
    }
}

const mapDispatchToProps = (dispatch) => ({
    addPriceLog: (priceLog) => dispatch(startAddPriceLog(priceLog))
});

export default connect(undefined, mapDispatchToProps)(PriceLogCell);