import React,{Component} from 'react';
import { Card, CardHeader, CardBody, Table} from 'reactstrap';
import HotelUserItem from './hotel.user.item';

class HotelUserList extends Component {
    render(){
        const {users, cardHeader, includeRemoveButton} = this.props;
        return (
            <div>
                <Card className="mb-4">
                    <CardHeader>{cardHeader}</CardHeader>
                    <CardBody>
                        <Table bordered>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Nombre</th>
                                <th>Correo electrónico</th>
                                <th>Rol</th>
                                {includeRemoveButton && <th className="text-right">Acciones</th>}
                            </tr>
                            </thead>
                            <tbody>
                            {
                                users.map((user, index) => {
                                    return (
                                        <HotelUserItem
                                            key={index} index={index+1}
                                            user={user}
                                            includeRemoveButton={includeRemoveButton}
                                        />
                                    )
                                })
                            }
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

export default HotelUserList;