import React, {Component} from 'react';
import {connect} from 'react-redux';
import HotelItem from './hotel.item';
import {Table, Card, CardTitle, CardBody} from 'reactstrap';
import AddHotelButton  from './add.hotel.button';
import ListViewHeading from '../listView/list.view.heading';
import {LoadingShading} from '../loadingIndicator/loading.shading';
import AlertMessages from '../messages/alert.messages';
import NoResultsFound from '../noResultsFound/no.results.found';

const columns = [
    {
        name: "Nombre",
        column: "Name",
        columnClass: "th-column"
    }, {
        name: "Ciudad",
        column: "City",
        columnClass: "th-column"
    }, {
        name: "Cantidad de habitaciones",
        column: "QuantityRooms",
        columnClass: "th-column text-center"
    },
    {
        name: "Acciones",
        column: "actions",
        columnClass: "action-items e-th-column",
    }
];




class HotelList extends Component {


    render() {
        const {hotels} = this.props;

        return (
            <div>
                { hotels.message.text && <AlertMessages message={hotels.message}/> }
                <div className="mt-1 items-list-grid">
                    <Card className="mb-1">
                        <CardBody>
                            <div className="row align-items-center mb-4">
                                <div className="col-xs-12 col-sm-12 col-md-7 col-lg-8">
                                    <CardTitle className="mb-0">
                                        Listado de Hoteles
                                    </CardTitle>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-5 col-lg-4 text-right">
                                    <AddHotelButton />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-lg-12">
                                    <Table responsive className="mb-0">
                                        <thead>
                                            <ListViewHeading
                                                columns={columns}
                                            />
                                        </thead>
                                        <tbody>
                                            {
                                                hotels.data.map((hotel) => {
                                                    return <HotelItem
                                                        key={hotel.id}
                                                        {...hotel}
                                                    />
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            { hotels.isLoadingHotels && <div className="modal-backdrop fade show bg-White"></div>}
                            { hotels.isLoadingHotels && <LoadingShading />}
                            { !hotels.isLoadingHotels && hotels.data.length === 0 && <NoResultsFound />}
                        </CardBody>
                    </Card>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    hotels: state.hotels
});

export default connect(mapStateToProps)(HotelList)