import axios from 'axios';
import {LOGIN_SUCCESS, LOGIN_REQUEST, LOGIN_ERROR, CLEAR_ERROR_MESSAGE, LOGOUT} from '../actions/types';
import * as AppConfig from '../config/app-base-config.json';
import {history} from '../routers/AppRouter';
import setAuthorizationToken from '../utils/set.authorization.token';
import {getCurrentUser, clearCurrentUser} from './current.user';
import {clearCurrentHotel} from './current.hotel';


const loginRequest = () => ({
    type: LOGIN_REQUEST
});

const loginSuccess = () => ({
    type: LOGIN_SUCCESS
});

const loginError = (error_message) => ({
    type: LOGIN_ERROR,
    error_message
});

const clearErrorMessage = () => ({
    type: CLEAR_ERROR_MESSAGE
});

export const startClearErrorMessage = () => {
    return (dispatch) => {
        dispatch(clearErrorMessage());
    }
};

export const logout = () => ({
    type: LOGOUT
});

export const startLogin = (email, password) => {
    return (dispatch) => {
        dispatch(loginRequest());
        const headers = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        return axios.post(AppConfig.API_BASE_URL.concat('/login'), {'email': email, 'password': password}, headers)
            .then((response) => {
                const token = response.data.token;
                setAuthorizationToken(token);
                localStorage.revhoteles_jwt = token;
                dispatch(getCurrentUser());
                dispatch(loginSuccess(token));
            })
            .catch((error) => {
                    const error_message = error.response ? error.response.data.error_message : "Ha ocurrido un error, intente mas tarde";
                    dispatch(loginError(error_message));
                    setTimeout(() => dispatch(clearErrorMessage()), 4000);
                    return false;
                }
            )
    }
};


export const startLogout = () => {
    return (dispatch) => {
        // return axios.post(AppConfig.URL_BASE_API.concat(AppConfig.RUTAS.LOGOUT))
        //     .then(() => {
        //         delete localStorage.revhoteles_jwt;
        //         setAuthorizationToken(localStorage.revhoteles_jwt);
        //         dispatch(clearCurrentUser());
        //         dispatch(logout());
        //         history.push('/');
        //     })
        //     .catch((error) => {
        //             const mensajeError = error.response.data.error_message;
        //             console.log('Error: ', mensajeError)
        //         }
        //     );

        delete localStorage.revhoteles_jwt;
        delete localStorage.current_hotel;
        setAuthorizationToken(localStorage.revhoteles_jwt);
        dispatch(clearCurrentUser());
        dispatch(clearCurrentHotel());
        dispatch(logout());
        history.push('/');
    }
};
