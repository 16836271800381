import axios from 'axios';
import * as AppConfig from '../config/app-base-config.json';
import {SET_FORECAST_FAILURE, SET_FORECAST_REQUEST, SET_FORECAST_SUCCESS, UPDATE_PRICE_LOG, PRICES_LOG_SAVED} from './types';
import moment from 'moment';

const setForecastRequest = () => ({
    type: SET_FORECAST_REQUEST
});

const setForecastSuccess = (data) => ({
    type: SET_FORECAST_SUCCESS,
    data
});

const setForecastFailure = (errorMessage) => ({
    type: SET_FORECAST_FAILURE,
    errorMessage
});

const addPriceLog = (priceLog) => ({
    type: UPDATE_PRICE_LOG,
    priceLog
});

const removeAllPricesLog = () => ({
    type: PRICES_LOG_SAVED
})

const headers = {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
};

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

const resetForecastToDefault = () => {
    return {
        id: 0,
        dayOfWeek: '',
        prices: [],
        calendarDate: '',
        current_situation: {
            rooms_available: [],
            total_rooms: 0,
            total_rooms_available: 0,
            occupied_rooms: 0,
            percent_occupation: 0,
            pickup: 0,
            sales: 0,
            ADR: 0,
            RevPAR: 0
        },
        estimation: {
            occupied_rooms: 0,
            percent_occupation: 0,
            difference: 0,
            sales: 0,
            ADR: 0,
            RevPAR: 0
        },
        budget: {
            occupied_rooms: 0,
            percent_occupation: 0,
            difference: 0,
            sales: 0,
            ADR: 0,
            RevPAR: 0
        }
    }
};

const getForecastResult = (forecastRow, previousForecast, index, currentCalendarDate) => {
    const occupiedRooms = forecastRow.current_situation.total_rooms - forecastRow.current_situation.total_rooms_available;
    const findPreviousForecast = previousForecast.find((forecast) => forecast.calendar_date === currentCalendarDate);
    const previousTotalRoomsAvailable = findPreviousForecast ? findPreviousForecast.total_quantity_available_rooms : 0;
    const percentOccupationCurrentSituation = Math.ceil((1 - forecastRow.current_situation.total_rooms_available / forecastRow.current_situation.total_rooms) * 100)
    const percentOccupationEstimation = Math.ceil((forecastRow.estimation.occupied_rooms / forecastRow.current_situation.total_rooms) * 100);

    const forecastResult = {
        ...forecastRow,
        id: index,
        dayOfWeek: capitalize(moment(currentCalendarDate, 'DD/MM/YYYY').format('ddd')),
        calendarDate: currentCalendarDate,
        current_situation: {
            ...forecastRow.current_situation,
            ADR: Math.ceil(forecastRow.current_situation.sales / occupiedRooms),
            RevPAR: Math.ceil(forecastRow.current_situation.sales / forecastRow.current_situation.total_rooms),
            occupied_rooms: occupiedRooms,
            pickup: previousTotalRoomsAvailable - forecastRow.current_situation.total_rooms_available,
            percent_occupation: percentOccupationCurrentSituation
        },
        estimation: {
            ...forecastRow.estimation,
            percent_occupation: percentOccupationEstimation,
            difference: percentOccupationCurrentSituation - percentOccupationEstimation,
            RevPAR: forecastRow.current_situation.total_rooms > 0 ? Math.ceil(forecastRow.estimation.sales / forecastRow.current_situation.total_rooms) : 0,
            ADR: forecastRow.estimation.occupied_rooms > 0 ? Math.ceil(forecastRow.estimation.sales / forecastRow.estimation.occupied_rooms) : 0
        }
    }

    return forecastResult;
}



const mapForecastData = (data, previousForecast) => {
    if (data.length === 0) {
        return [];
    }
    let results = [];
    let index = 1;
    let currentCalendarDate = data[0].calendar_date;
    let forecastRow = resetForecastToDefault();
    data.forEach((forecast, pos) => {
        if (forecast.calendar_date === currentCalendarDate) {

            if (forecast.priceInfo) {
                forecastRow.prices.push({id: forecast.priceInfo.id, price: forecast.priceInfo.price});
            } else {
                forecastRow.prices.push({id: forecast.id, price: '000'});
            }

            forecastRow.current_situation.rooms_available.push({
                id: forecast.room_type.id,
                quantity_available_rooms: forecast.quantity_available_rooms
            });
            forecastRow.current_situation.total_rooms += forecast.room_type.quantity;
            forecastRow.current_situation.total_rooms_available += forecast.quantity_available_rooms;
            forecastRow.current_situation.sales += parseFloat(forecast.amount ? forecast.amount : 0.00);

            forecastRow.estimation.occupied_rooms += forecast.occupancy ? forecast.occupancy.quantity_reserved_rooms : 0;
            forecastRow.estimation.sales += forecast.occupancy ? parseFloat(forecast.occupancy.amount) : 0;
            if(data.length === pos + 1){
                const forecastAux = getForecastResult(forecastRow, previousForecast, index, currentCalendarDate);

                results.push(forecastAux);
            }

        } else {

            const forecastAux = getForecastResult(forecastRow, previousForecast, index, currentCalendarDate);

            results.push(forecastAux);
            currentCalendarDate = forecast.calendar_date;
            forecastRow = resetForecastToDefault();
            if (forecast.priceInfo) {
                forecastRow.prices.push({id: forecast.priceInfo.id, price: forecast.priceInfo.price});
            } else {
                forecastRow.prices.push({id: forecast.id, price: '000'});
            }
            forecastRow.current_situation.rooms_available.push({
                id: forecast.room_type.id,
                quantity_available_rooms: forecast.quantity_available_rooms
            });
            forecastRow.current_situation.total_rooms += forecast.room_type.quantity;
            forecastRow.current_situation.total_rooms_available += forecast.quantity_available_rooms;
            forecastRow.current_situation.sales += parseFloat(forecast.amount ? forecast.amount : 0.00);

            forecastRow.estimation.occupied_rooms += forecast.occupancy ? forecast.occupancy.quantity_reserved_rooms : 0;
            index++;
        }
    });

    return results;
};


const toMixForecasts = (forecasts, forecastsToCompare) => {
    if (forecastsToCompare.length === 0) {
        return forecasts;
    } else {
        let results = [];
        forecasts.forEach((forecast, index) => {
            forecast.isComparative = false;
            const occupiedRoomsClosure = forecastsToCompare[index] ? forecastsToCompare[index].estimation.occupied_rooms : 0;
            const occupiedRoomsComparativeSnapshot = forecastsToCompare[index] ? forecastsToCompare[index].current_situation.occupied_rooms : 0;

            forecast.estimation.occupied_rooms = occupiedRoomsClosure + forecast.current_situation.occupied_rooms - occupiedRoomsComparativeSnapshot;
            forecast.estimation.percent_occupation = Math.ceil((forecast.estimation.occupied_rooms / forecast.current_situation.total_rooms) * 100);
            forecast.estimation.difference = forecast.current_situation.percent_occupation - forecast.estimation.percent_occupation;
            forecast.estimation.ADR = Math.ceil(forecast.estimation.sales / forecast.estimation.occupied_rooms);

            results.push(forecast);
            if (index <= forecastsToCompare.length - 1) {
                forecastsToCompare[index].isComparative = true;
                results.push(forecastsToCompare[index]);
            }
        })

        return results;
    }

};

export const startSetForecast = (values) => {
    return (dispatch) => {
        dispatch(setForecastRequest());
        axios.get(AppConfig.API_BASE_URL.concat(`/hotel/${values.hotelId}/forecasts`).concat(`?snapshotDate=${values.snapshotDate}&startDate=${values.startDate}&endDate=${values.endDate}&comparisonYear=${values.comparisonYear}`), headers).then((response) => {

            const forecasts = mapForecastData(response.data.forecasts, response.data.previousForecast);
            const forecastsToCompare = mapForecastData(response.data.forecastsToCompare, response.data.previousForecastToCompare);

            const mixedForecasts = toMixForecasts(forecasts, forecastsToCompare);
            dispatch(setForecastSuccess(mixedForecasts));
        }).catch((error) => {
            const erroMessage = "Error occurred"
            dispatch(setForecastFailure(erroMessage))
        })
    }
}

export const startSavePricesLog = (pricesToModify) => {
    return (dispatch) => {
        axios.post(AppConfig.API_BASE_URL.concat('/forecast/price-logs/updates'), {pricesToModify}, headers).then((response) => {
            dispatch(removeAllPricesLog());
        }).catch((error) => {
            const erroMessage = "Error occurred"
            //dispatch(setForecastFailure(erroMessage))
        })
    }
}

export const startAddPriceLog = (priceValue) => {
    return(dispatch) => {
        const today = moment().format('YYYY-MM-DD');
        const priceValueWithDate = {...priceValue, modification_date: today};
        dispatch(addPriceLog(priceValueWithDate));
    }
}