import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Table, Card, CardTitle, CardBody} from 'reactstrap';
import UserItem from './user.item';
import ListViewHeading from '../listView/list.view.heading';
import AddUserButton from './add.user.button';
import {LoadingShading} from '../loadingIndicator/loading.shading';
import AlertMessages from '../messages/alert.messages';
import NoResultsFound from '../noResultsFound/no.results.found';

const columns = [
    {
        name: "Nombre",
        column: "Name",
        columnClass: "th-column"
    }, {
        name: "Correo electrónico",
        column: "Email",
        columnClass: "th-column",
        isSortable: true
    }, {
        name: "Role",
        column: "Role",
        columnClass: "th-column",
        isSortable: true
    },
    {
        name: "Acciones",
        column: "actions",
        columnClass: "action-items e-th-column",
    }
];

class UserList extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {users, currentUser} = this.props;

        return (
            <div>
                { users.message.text && <AlertMessages message={users.message}/> }
                <div className="mt-1 items-list-grid">
                    <Card className="mb-1">
                        <CardBody>
                            <div className="row align-items-center mb-4">
                                <div className="col-xs-12 col-sm-12 col-md-7 col-lg-8">
                                    <CardTitle className="mb-0">
                                        Listado de Usuarios registrados en el sistema
                                    </CardTitle>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-5 col-lg-4 text-right">
                                    <AddUserButton />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-lg-12">
                                    <Table responsive className="mb-0">
                                        <thead>
                                        <ListViewHeading
                                            columns={columns}
                                        />
                                        </thead>
                                        <tbody>
                                        {
                                            users.data.map((user) => {
                                                return <UserItem
                                                    key={user.id}
                                                    userId={user.id}
                                                    name={user.name}
                                                    email={user.email}
                                                    rol={user.role.name}
                                                    isCurrentUser={user.id === currentUser.id}
                                                />;
                                            })
                                        }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            { users.isLoadingUsers && <div className="modal-backdrop fade show bg-White"></div>}
                            { users.isLoadingUsers && <LoadingShading />}
                            { !users.isLoadingUsers && users.data.length === 0 && <NoResultsFound />}
                        </CardBody>
                    </Card>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    users: state.users,
    currentUser: state.current_user
});


export default connect(mapStateToProps)(UserList);

