import React, {Component} from 'react'
import {Field, reduxForm} from 'redux-form';
import {FormGroup, Button, ModalBody, ModalFooter} from 'reactstrap';
import TextField from '../form/text.field';
import {combineValidators, required, isPassword} from '../../utils/validation';

const validate = (values) => {
    const errors = {};

    errors.password = combineValidators('Password', values.password, required);
    errors.confirmPassword = combineValidators(values.password, values.confirmPassword, isPassword);

    return errors;
};

class ChangePasswordForm extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {handleSubmit, toggleModal} = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <div>
                    <ModalBody>
                        <FormGroup>
                            <Field
                                id="userPassword"
                                name="password"
                                required={true}
                                component={TextField}
                                type="password"
                                placeholder="Contraseña"
                                label="Contraseña"
                                autocomplete="new-password"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Field
                                id="userPasswordConfirmation"
                                name="confirmPassword"
                                required={true}
                                component={TextField}
                                type="password"
                                placeholder="Repita la contraseña"
                                label="Repita la contraseña"
                                autocomplete="new-password"
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                        <Button color="primary" type="submit">Guardar</Button>
                    </ModalFooter>
                </div>
            </form>
        )
    }
}

export default reduxForm({
    form: 'changePasswordForm',
    validate,
    enableReinitialize: true
})(ChangePasswordForm);
