import React, {Component} from 'react';
import {
    Button, Modal, ModalHeader, ModalBody,
    ModalFooter, Card, CardHeader, CardBody
} from 'reactstrap';
import Autocomplete from '../form/autocomplete';
import {connect} from 'react-redux';
import {MdAdd, MdDelete} from 'react-icons/md';
import Roles from '../../roles';
import {addUsersToHotel} from '../../actions/current.hotel';

class AddUserToHotel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openModal: false,
            assignees: []
        }
    }

    toggleModal = (event) => {
        event.preventDefault();
        this.setState(() => ({
            openModal: !this.state.openModal,
            assignees: []
        }))
    };

    handleAssignUser = (valueSuggested) => {
        this.setState((prevState) => ({
            assignees: [...prevState.assignees, valueSuggested]
        }));

    }

    handleRemoveUser = (userId) => {
        this.setState((prevState) => ({
            assignees: prevState.assignees.filter((user) => user.id !== userId)
        }))
    };

    handleSaveUsers = () => {
        const userIds = this.state.assignees.map((it) => it.id);
        this.props.addUsersToHotel(this.props.current_hotel.id, userIds);
        this.setState((prevState) => ({
            openModal: !prevState.openModal
        }));

    }

    render() {
        const users = this.props.users.data.filter((user) =>
            user.role.id !== Roles.ADMIN.id &&
            this.state.assignees.map((it) => it.email).indexOf(user.email) === -1 &&
            this.props.current_hotel.users.map((it) => it.email).indexOf(user.email) === -1
        ).map((user) => {
            return {
                id: user.id, email: user.email
            }
        });

        const {openModal} = this.state;
        return (
            <div >
                <Button
                    color="success"
                    onClick={this.toggleModal}
                >
                    <MdAdd
                        size={20}
                        className="mr-2"
                    />
                    Adicionar Usuario
                </Button>
                <Modal isOpen={openModal} toggle={this.toggleModal} className="add-user-to-hotel">
                    <ModalHeader toggle={this.toggleModal}>
                        Asignando Usuario al Hotel
                    </ModalHeader>
                    <ModalBody>
                        <Autocomplete
                            users={users}
                            onSetValueSelected={this.handleSetValueSelected}
                            onAssignUser={this.handleAssignUser}
                        />
                        <Card>
                            <CardHeader>Usuarios</CardHeader>
                            { this.state.assignees.length === 0 && <CardBody />}
                            { this.state.assignees.length > 0 &&
                            <CardBody className="list-assigned-users">
                                {
                                    this.state.assignees.map((user) => {
                                        return (
                                            <div key={user.id} className="c-flex hotel-user-item">
                                                <span>{user.email}</span>
                                                <MdDelete size={20} onClick={() => this.handleRemoveUser(user.id)}/>
                                            </div>
                                        )
                                    })
                                }
                            </CardBody>
                            }
                        </Card>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggleModal}>Cancelar</Button>
                        <Button color="primary" onClick={this.handleSaveUsers}>Guardar</Button>
                    </ModalFooter>
                </Modal>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    roles: state.roles,
    users: state.users,
    current_hotel: state.current_hotel.hotel_info
});

const mapDisptachToProps = (dispatch) => ({
    addUsersToHotel: (hotelId, userIds) => dispatch(addUsersToHotel(hotelId, userIds))
});


export default connect(mapStateToProps, mapDisptachToProps)(AddUserToHotel);