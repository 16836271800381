import axios from 'axios';
import * as AppConfig from '../config/app-base-config.json';
import {SET_ROLES} from './types';

const setRoles = (roles) => ({
    type:  SET_ROLES,
    roles
});

export const startSetRoles = () => {
    return (dispatch) => {
        axios.get(AppConfig.API_BASE_URL.concat('/roles')).then((response) => {
            const roles = response.data.roles.reverse();
            dispatch(setRoles(roles));
        })
    }
}