import React, {Component} from 'react';
import EditRoomButton from './edit.room.button';
import RemoveRoomButton from './remove.room.button';
import {connect} from 'react-redux';
import {startSetHotels} from '../../actions/hotels';

class RoomItem extends Component {
    constructor(props) {
        super(props);
        this.state = {openUserModal: false}
    }


    render() {
        const {id, name, hotel_name, hotel_id, quantity, max_quantity_guest, short_name} = this.props;
        return (
            <tr role="row" className="list-item">
                <td role="cell" className="py-2 align-middle">{name}</td>
                <td role="cell" className="py-2 align-middle text-center">{quantity}</td>
                <td role="cell" className="py-2 align-middle text-center">{max_quantity_guest}</td>
                <td role="cell" className="py-2 align-middle text-center">{short_name ? short_name : '###'}</td>
                <td role="cell" className="py-2 align-middle">{hotel_name}</td>
                <td role="cell" className="action-items py-2 align-middle">
                    <div className="small-buttons">
                        <EditRoomButton roomId={id}  onSetHotels={this.props.setHotels}/>
                        <RemoveRoomButton roomId={id} hotelId={hotel_id} onSetHotels={this.props.setHotels}/>
                    </div>
                </td>
            </tr>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    setHotels: () => dispatch(startSetHotels())
});

export default connect(undefined, mapDispatchToProps)(RoomItem);