import axios from 'axios';
import * as AppConfig from '../config/app-base-config.json';
import {
    SET_USERS,
    ADD_USER,
    REMOVE_USER,
    EDIT_USER,
    REQUEST_USERS,
    CLEAR_MESSAGE,
    CHANGE_PASSWORD
} from './types';
import {startSetHotels} from './hotels';

const setUsers = (users) => ({
    type: SET_USERS,
    users
});

const setAddUser = (user) => ({
    type: ADD_USER,
    user
})

const setEditUser = (id, updates) => ({
    type: EDIT_USER,
    id,
    updates
})

const setRemoveUser = (id) => ({
    type: REMOVE_USER,
    id,
})

const requestUsers = () => ({
    type: REQUEST_USERS
});

const setPassword = () => ({
    type: CHANGE_PASSWORD
});

const clearMessage = () => ({
    type: CLEAR_MESSAGE
});


export const startSetUsers = () => {
    return (dispatch) => {
        dispatch(requestUsers());
        axios.get(AppConfig.API_BASE_URL.concat('/users')).then((response) => {
            const users = response.data.users;
            dispatch(setUsers(users));
            setTimeout(() => dispatch(clearMessage()), 3000);
        })
    }
}


export const addUser = (userInfo) => {
    return (dispatch) => {
        const headers = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        axios.post(AppConfig.API_BASE_URL.concat('/users'), userInfo, headers).then((response) => {
            const user = response.data.user;
            dispatch(setAddUser(user));
            setTimeout(() => dispatch(clearMessage()), 3000);
        })
    }
}


export const editUser = (id, updates) => {
    return (dispatch) => {
        const headers = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };

        axios.put(AppConfig.API_BASE_URL.concat(`/users/${id}`), updates, headers).then((response) => {
            dispatch(setEditUser(id, response.data.user));
            dispatch(startSetHotels());
            setTimeout(() => dispatch(clearMessage()), 3000);
        })
    }
}


export const removeUser = (id) => {
    return (dispatch) => {
        const headers = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        axios.delete(AppConfig.API_BASE_URL.concat(`/users/${id}`), headers).then((response) => {
            dispatch(setRemoveUser(id));
            dispatch(startSetHotels());
            setTimeout(() => dispatch(clearMessage()), 3000);
        })
    }
}

export const changePassword = (userId, password) => {
    return (dispatch) => {
        const headers = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        axios.patch(AppConfig.API_BASE_URL.concat(`/users/${userId}`), password, headers).then((response) => {
            dispatch(setPassword());
            setTimeout(() => dispatch(clearMessage()), 3000);
        })
    }
}