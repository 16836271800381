import React, {Component} from 'react';
import {connect} from 'react-redux';
import {startSetRoles} from '../../actions/roles';
import {startSetUsers} from '../../actions/users';
import HotelUserList from './hotel.user.list';
import Roles from '../../roles';
import AddUserToHotel from './add.user.to.hotel';
import {hasPermission} from '../../utils/permissions.helper';
import Permission from '../../permissions';
import './hotel.user.page.scss';

class HotelUsersPage extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.setRoles();
        this.props.setUsers();
    }


    render() {
        const {current_hotel, current_user} = this.props;
        const userAdministrators = current_hotel.users.filter((user) => user.role_id === Roles.ADMIN.id);
        const otherUsers = current_hotel.users.filter((user) => user.role_id !== Roles.ADMIN.id);
        const canAddUser = hasPermission(current_user.permissions, Permission.SUBJECTS.USER, Permission.ACTIONS.CREATE);
        const canViewSettings = hasPermission(current_user.permissions, Permission.SUBJECTS.SETTINGS, Permission.ACTIONS.READ);
        return (
            <div>
                {current_hotel.name &&
                <div className="section-header">
                    <h4 className="content-title">Usuarios del hotel
                        <span className="hotel-name">{this.props.current_hotel.name}</span>
                    </h4>
                    {canAddUser &&
                        <AddUserToHotel />
                    }
                </div>
                }

                <HotelUserList
                    cardHeader="Administradores"
                    users={userAdministrators}
                    includeRemoveButton = {false}
                />
                {otherUsers.length > 0 &&
                <HotelUserList
                    cardHeader="Otros usuarios"
                    users={otherUsers}
                    includeRemoveButton = {canViewSettings ? true : false}
                />
                }
            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    current_hotel: state.current_hotel.hotel_info,
    current_user: state.current_user
});

const mapDistpachToProps = (dispatch) => ({
    setRoles: () => dispatch(startSetRoles()),
    setUsers: () => dispatch(startSetUsers())
});

export default connect(mapStateToProps, mapDistpachToProps)(HotelUsersPage);