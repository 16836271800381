import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import authReducer from '../reducers/auth';
import currentUserReducer from '../reducers/currentUser';
import currentHotelReducer from '../reducers/currentHotel';
import hotelsReducer from '../reducers/hotels';
import rolesReducer from '../reducers/roles';
import usersReducer from '../reducers/users';
import forecastReducer from '../reducers/forecast';
import roomsReducer from '../reducers/rooms';
import { reducer as formReducer } from 'redux-form';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
    const store = createStore(
        combineReducers({
            auth: authReducer,
            current_user: currentUserReducer,
            current_hotel: currentHotelReducer,
            users: usersReducer,
            hotels: hotelsReducer,
            rooms: roomsReducer,
            roles: rolesReducer,
            forecast: forecastReducer,
            form: formReducer
        }),
        composeEnhancers(applyMiddleware(thunk))
    );

    return store;
};