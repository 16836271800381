import React, {Component}from 'react';
import {connect} from 'react-redux';
import RemoveHotelUserButton from './remove.hotel.user.button';

class HotelUserItem extends Component {
    render() {
        const {index, user, roles, includeRemoveButton} = this.props;
        const role = roles.find(({id}) => id == user.role_id);
        return (
            <tr>
                <th scope="row">{index}</th>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{role ? role.name : ''}</td>
                {includeRemoveButton && <td className="text-right">
                    <div className="small-buttons">
                        <RemoveHotelUserButton userId={user.id}/>
                    </div>
                </td>}
            </tr>
        )
    }
}

const mapStateToProps = (state) => ({
    roles: state.roles
})

export default connect(mapStateToProps)(HotelUserItem);