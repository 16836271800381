import React, {Component} from 'react';
import {Button, Form, FormGroup, Input, InputGroup, InputGroupAddon, FormText, Alert} from 'reactstrap';
import {MdPerson, MdLock} from 'react-icons/md';
import {isEmpty, isEmail} from '../../utils/validation';


class LoginForm extends Component {

    constructor(props) {
        super(props);
        this.state = {email: '', password: '', submitted: false, emailError: '', passwordError: ''}
    }

    handleEmailChange = (e) => {
        const email = e.target.value.trim();
        this.setState(() => ({
            email
        }));

        if (this.state.submitted) {
            this.setState(() => ({
                emailError: isEmail('Email address', email)
            }))
        }
        this.props.removeErrorMessage();
    };


    handlePasswordChange = (e) => {
        const password = e.target.value;
        this.setState(() => ({
            password
        }));
        if (this.state.submitted) {
            this.setState(() => ({
                passwordError: isEmpty(password, 'Password')
            }))
        }
        this.props.removeErrorMessage();
    };


    onSubmit = (e) => {
        e.preventDefault();
        const {email, password} = this.state;
        this.setState(() => ({
            submitted: true,
            emailError: isEmail('Email address', email),
            passwordError: isEmpty(password, 'Password')
        }));
        if (!isEmpty(password) && !isEmail('Email address', email)) {
            this.props.onSubmit(email, password).then(() => {
                if(this.props.errorMessage){
                    this.setState(() => ({
                        password: ''
                    }))
                }
            })
        }
    };

    render() {
        const {email, password, emailError, passwordError} = this.state;
        const {errorMessage} = this.props;

        return (
            <div className="box-layout__form">
                {
                    errorMessage && <Alert color="danger" className="text-left">
                        {errorMessage}
                    </Alert>
                }
                <Form onSubmit={this.onSubmit}>
                    <FormGroup>
                        <InputGroup className={emailError ? 'has-error' : ''}>
                            <InputGroupAddon addonType="prepend">
                                        <span className="input-group-text">
                                            <MdPerson size={20}/>
                                        </span>
                            </InputGroupAddon>
                            <Input value={email} placeholder="Email address" type="text" autoComplete="username"
                                   autoFocus
                                   onChange={this.handleEmailChange}/>
                        </InputGroup>
                        {
                            emailError && <FormText color="danger">
                                {emailError}
                            </FormText>
                        }
                    </FormGroup>
                    <FormGroup>
                        <InputGroup className={passwordError ? 'has-error' : ''}>
                            <InputGroupAddon addonType="prepend">
                                        <span className="input-group-text">
                                            <MdLock size={20}/>
                                        </span>
                            </InputGroupAddon>
                            <Input value={password} placeholder="Password" type="password"
                                   onChange={this.handlePasswordChange} autoComplete="current-password"/>
                        </InputGroup>
                        <FormText color="danger">
                            {passwordError}
                        </FormText>
                    </FormGroup>
                    <Button color="success">Login</Button>
                </Form>
            </div>
        )
    }

}

export default LoginForm;
