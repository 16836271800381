import React from 'react';
import EditHotelButton from './edit.hotel.button';
import RemoveHotelButton from './remove.hotel.button';

const HotelItem = ({id, name, city, quantity_rooms}) => (
    <tr role="row" className="list-item">
        <td role="cell" className="py-2 align-middle">{name}</td>
        <td role="cell" className="py-2 align-middle">{city}</td>
        <td role="cell" className="py-2 align-middle text-center">{quantity_rooms}</td>
        <td role="cell" className="action-items py-2 align-middle">
            <div className="small-buttons">
                <EditHotelButton hotelId={id} />
                <RemoveHotelButton hotelId={id} hotelName={name}/>
            </div>
        </td>
    </tr>
);

export default HotelItem;