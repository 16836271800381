import {SET_ROOMS, EDIT_ROOM, ADD_ROOM, REMOVE_ROOM, REQUEST_ROOMS, CLEAR_MESSAGE} from '../actions/types';
import {MESSAGE_TYPES} from '../utils/constants';

const roomsDefaultState = {
    isLoadingRooms: false,
    data: [],
    message: {type: "", text: ""}
}


export default (state = roomsDefaultState, action) => {
    switch (action.type) {
        case REQUEST_ROOMS:
            return {
                ...state,
                isLoadingRooms: true
            };
        case SET_ROOMS:
            return {
                ...state,
                data: action.rooms,
                isLoadingRooms: false
            }
        case ADD_ROOM:
            return {
                ...state,
                data: [...state.data, action.room],
                message: {type: MESSAGE_TYPES.success, text: "Habitación adicionada correctamente"}
            };
        case EDIT_ROOM:
            const dataUpdated = state.data.map((room) => {
                if (room.id === action.id) {
                    return {
                        ...room,
                        ...action.updates
                    };
                } else {
                    return room;
                }
            });
            return {
                ...state,
                data: dataUpdated,
                message: {type: MESSAGE_TYPES.success, text: "Habitación fue editada correctamente"}
            }
        case REMOVE_ROOM:
            const newData = state.data.filter(({id}) => id !== action.id);
            return {
                ...state,
                data: newData,
                message: {type: MESSAGE_TYPES.success, text: "Habitación fue eliminada correctamente"}
            }
        case CLEAR_MESSAGE:
            return {
                ...state,
                message: {type: "", text: ""}
            }
        default:
            return state;
    }
}