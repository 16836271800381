import {LOGIN_SUCCESS, LOGIN_ERROR, LOGIN_REQUEST, LOGOUT, CLEAR_ERROR_MESSAGE} from '../actions/types';

const authReducerDefaultState = (revhoteles_jwt => ({
    logging: false,
    logged: revhoteles_jwt ? true : false,
    error_message: ''
}))(localStorage.revhoteles_jwt);


export default (state = authReducerDefaultState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                logging: true
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                logged: true,
                logging: false,
                error_message: ''
            }
        case LOGIN_ERROR:
            return {
                ...state,
                logging: false,
                error_message: action.error_message
            }
        case CLEAR_ERROR_MESSAGE:
            return {
                ...state,
                error_message: ''
            }
        case LOGOUT:
            return {
                ...state,
                logging: false,
                logged: false
            }
        default:
            return state;
    }
};