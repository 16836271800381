import React from 'react';
import {Alert} from 'reactstrap';
import { MESSAGE_TYPES } from '../../utils/constants';


const AlertMessages = ({message}) => (
    <div className="e-messages">
        <div>
            <Alert color={`${MESSAGE_TYPES.success === message.type ? 'success' : 'danger'}`}>
                {message.text}
            </Alert>
        </div>
    </div>
);

export default AlertMessages;