import {SET_CURRENT_HOTEL} from '../actions/types';

const currentHotelDefaultState = (current_hotel => ({
     hotel_info: current_hotel ? JSON.parse(current_hotel) : {id: 0, name: '', city: '', quantity_rooms: 0, users: [], room_types: []}
}))(localStorage.current_hotel);

export default (state = currentHotelDefaultState, action) => {
    switch (action.type) {
        case SET_CURRENT_HOTEL:
            return {
                hotel_info: action.current_hotel
            }
        default:
            return state;
    }
};