import validator from 'validator';

export const isEmpty = (value, fieldName = 'Este campo') => {
    if (!value.toString().trim().length) {
        // We can return string or jsx as the 'error' prop for the validated Component
        return `${fieldName} es requerido`;
    }
};

export const isEmail = (label, value) => {
    if (!value) {
        return  `${label} es requerido`
    } else if (!validator.isEmail(value)) {
        return `${value} no es un correo válido.`
    }
};

export const isPassword = (value, confirmPassword) => {

    if (value !== confirmPassword) {
        return 'Passwords no son iguales';
    }
};

export const required = (label, value) => {
    return (value ? undefined : `${label} es requerido.`);
};

export const maxLength = (max) => {
    return (label, value) => {
        return value && value.length > max ? `${label} must be ${max} characters or less.` : undefined;
    };
};

export const minLength = (min) => {
    return (label, value) => {
        return value && value.length < min ? `${label} must be ${min} characters or more.` : undefined;
    };
};

export const combineValidators = (label, value, ...validators) => {
    let error;

    validators.forEach((validator) => {
        if (error === undefined) {
            error = validator(label, value);
        }
    });

    return error;
};