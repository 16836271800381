import React, {Component} from 'react';
import {
    Collapse,
    Nav,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    UncontrolledDropdown
} from 'reactstrap';

import {history} from '../../routers/AppRouter'

import {NavLink} from 'react-router-dom';
import {
    MdSupervisorAccount,
    MdHotel,
    MdExitToApp,
    MdAssignmentInd,
    MdCompareArrows,
    MdSwapHoriz,
    MdAccountBalance
} from 'react-icons/md'

import {hasPermission} from '../../utils/permissions.helper';
import Permission from '../../permissions';

import './menu.scss';

class Menu extends Component {

    goToHotels = () => {
        return history.push('/hotels');
    }

    goToUsers = () => {
        return history.push('/users')
    }

    goToRooms = () => {
        return history.push('/rooms');
    }

    goToHotelUsers = () => {
        return history.push('/hotel/users');
    }


    render() {
        const {isOpen, user, onToggleChangeHotelModal, onLogout} = this.props;
        const canViewSettings = hasPermission(user.permissions, Permission.SUBJECTS.SETTINGS, Permission.ACTIONS.READ);
        return (
            <Collapse isOpen={isOpen} navbar>
                <Nav className="ml-auto" navbar>
                    <UncontrolledDropdown nav inNavbar>
                        {
                            user &&
                            <DropdownToggle nav caret>
                                {user.email }
                            </DropdownToggle>
                        }
                        <DropdownMenu right>
                            <DropdownItem onClick={this.goToHotelUsers} className="menu-option">
                                <MdAssignmentInd size={20} className="mr-2"/>
                                Asignar usuarios a hotel
                            </DropdownItem>
                            <DropdownItem onClick={onToggleChangeHotelModal} className="menu-option">
                                <MdSwapHoriz size={20} className="mr-2"/>
                                Cambiar de hotel
                            </DropdownItem>


                            {
                                canViewSettings &&
                                <div>
                                    <DropdownItem divider/>
                                    <DropdownItem onClick={this.goToHotels} className="menu-option">
                                        <MdAccountBalance size={20} className="mr-2"/>
                                        Hoteles
                                    </DropdownItem>
                                    <DropdownItem onClick={this.goToUsers} className="menu-option">
                                        <MdSupervisorAccount size={20} className="mr-2"/>
                                        Usuarios
                                    </DropdownItem>
                                    <DropdownItem onClick={this.goToRooms} className="menu-option">
                                        <MdHotel size={20} className="mr-2"/>
                                        Habitaciones
                                    </DropdownItem>
                                </div>

                            }
                            <DropdownItem divider/>
                            <DropdownItem onClick={onLogout} className="menu-option">
                                <MdExitToApp size={20} className="mr-2"/>
                                Logout
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Nav>
            </Collapse>
        )
    }
}

export default Menu;