import axios from 'axios';
import * as AppConfig from '../config/app-base-config.json';
import {SET_ROOMS, ADD_ROOM, EDIT_ROOM, REMOVE_ROOM, REQUEST_ROOMS, CLEAR_MESSAGE} from './types';
import {startSetHotels} from './hotels';

const setRooms = (rooms) => ({
    type:  SET_ROOMS,
    rooms
});


const setAddRoom = (room) => ({
    type: ADD_ROOM,
    room
})

const setEditRoom = (id, updates) => ({
    type: EDIT_ROOM,
    id,
    updates
})

const setRemoveRoom = (id) => ({
    type: REMOVE_ROOM,
    id,
})

const requestRooms = () => ({
    type: REQUEST_ROOMS
})

const clearMessage = () => ({
    type: CLEAR_MESSAGE
});

export const startSetRooms = () => {
    return (dispatch) => {
        dispatch(requestRooms());
        axios.get(AppConfig.API_BASE_URL.concat('/room-type')).then((response) => {
            const rooms = response.data.rooms;
            dispatch(setRooms(rooms));
        })
    }
}

const headers = {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
};


export const addRoom = (roomInfo) => {
    return (dispatch) => {

        axios.post(AppConfig.API_BASE_URL.concat('/room-type'), roomInfo, headers).then((response) => {
            const room = response.data.room;
            dispatch(setAddRoom(room));
            startSetHotels();
            setTimeout(() => dispatch(clearMessage()), 3000);
        })
    }
};


export const editRoom = (id, updates) => {
    return (dispatch) => {

        axios.put(AppConfig.API_BASE_URL.concat(`/room-type/${id}`), updates, headers).then((response) => {
            dispatch(setEditRoom(id, response.data.room));
            setTimeout(() => dispatch(clearMessage()), 3000);
        })
    }
};


export const removeRoom = (id) => {
    return (dispatch) => {

        return axios.delete(AppConfig.API_BASE_URL.concat(`/room-type/${id}`),  headers).then((response) => {
            setTimeout(() => dispatch(clearMessage()), 3000);
            return dispatch(setRemoveRoom(id));
        })
    }
};

