import axios from 'axios';
import {SET_CURRENT_USER, CLEAR_CURRENT_USER} from '../actions/types';
import * as AppConfig from '../config/app-base-config.json';
import {setCurrentHotel} from './current.hotel';

const setCurrentUser = (current_user) => ({
    type: SET_CURRENT_USER,
    current_user
});

const resetCurrentUser = (current_user) => ({
    type: CLEAR_CURRENT_USER,
    current_user
});

export const getCurrentUser = () => {
    return (dispatch) => {
        axios.get(AppConfig.API_BASE_URL.concat('/current-user')).then((response) => {
            const current_user = response.data.current_user;
            const hotels = current_user.hotels;
            if (hotels.length > 0) {
                const current_hotel = localStorage.current_hotel && localStorage.current_hotel.id !== 0 ? JSON.parse(localStorage.current_hotel) : hotels[0];
                localStorage.current_hotel = JSON.stringify(current_hotel);
                dispatch(setCurrentHotel(current_hotel));
            } else {
                dispatch(setCurrentHotel({}));
            }

            dispatch(setCurrentUser(current_user));
        }).catch((error) => {
            console.log("Error", error.response)
        })
    }
};

export const clearCurrentUser = () => {
    return (dispatch) => {
        const current_user = {};
        dispatch(resetCurrentUser(current_user));
    }
};