import React, {Component} from 'react';
import {connect} from 'react-redux';
import {removeRoom} from '../../actions/rooms';
import {MdDelete} from 'react-icons/md';
import {Button, Modal, ModalHeader, ModalFooter, ModalBody, Tooltip} from 'reactstrap';
import {setCurrentHotel} from '../../actions/current.hotel';
import {startSetHotels} from '../../actions/hotels';


class RemoveRoomButton extends Component {
    constructor(props) {
        super(props);
        this.state = {openModal: false, tooltipOpen: false}
    }

    componentWillUnmount() {
        this.updateCurrentHotel();
    }

    toggleModal = (event) => {
        event.preventDefault();
        this.setState(() => ({
            openModal: !this.state.openModal,
        }))
    };

    toggle = () => {
        this.setState((prevState) => ({
            tooltipOpen: !prevState.tooltipOpen
        }))
    }

    updateCurrentHotel = () => {
        if (this.props.hotelId === this.props.currentHotelId) {
            const hotel_selected = this.props.hotels.data.find((it) => it.id == this.props.hotelId);
            localStorage.current_hotel = JSON.stringify(hotel_selected);
            this.props.setCurrentHotel(hotel_selected);
        }
    }

    removeRoom = () => {
        this.props.removeRoom(this.props.roomId).then(() => {
            this.props.setHotels();
        });
    }

    render() {
        return (
            <div>
                <Button
                    id={"deleteRoomButton" + this.props.roomId}
                    color="danger"
                    onClick={this.toggleModal}
                    className="ml-3"
                >
                    <MdDelete/>
                </Button>
                <Tooltip
                    placement="bottom"
                    isOpen={this.state.tooltipOpen}
                    target={"deleteRoomButton" + this.props.roomId}
                    toggle={this.toggle}
                    trigger="hover"
                    delay={{show: 10, hide: 50}}
                >
                    Eliminar habitación
                </Tooltip>
                <Modal isOpen={this.state.openModal} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>
                        Eliminar Habitación
                    </ModalHeader>
                    <ModalBody>
                        ¿Está seguro que desea eliminar esta habitación?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.removeRoom}>Estoy seguro</Button>
                        <Button color="secondary" onClick={this.toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    hotels: state.hotels,
    currentHotelId: state.current_hotel.hotel_info.id
});

const mapDispatchToProps = (dispatch) => ({
    removeRoom: (id) => dispatch(removeRoom(id)),
    setCurrentHotel: (hotelInfo) => dispatch(setCurrentHotel(hotelInfo)),
    setHotels: () => dispatch(startSetHotels())
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoveRoomButton);