import React, {Component} from 'react';
import {MdLock} from 'react-icons/md';
import {Button, Modal, ModalHeader, Tooltip} from 'reactstrap';
import ChangePasswordForm from './change.password.form';
import {connect} from 'react-redux';
import {changePassword} from '../../actions/users';

class ChangePasswordButton extends Component {
    constructor(props) {
        super(props);
        this.state = {openModal: false, tooltipOpen: false}
    }

    toggleModal = (event) => {
        event.preventDefault();
        this.setState(() => ({
            openModal: !this.state.openModal,
        }))
    };

    toggle = () => {
        this.setState((prevState) => ({
            tooltipOpen: !prevState.tooltipOpen
        }))
    }


    submit = (values) => {
        delete values.confirmPassword;
        const password = JSON.stringify(values, null, 2);
        this.props.changePassword(this.props.userId, password);
        this.setState(() => ({
            openModal: false
        }))
    };

    render() {
        const {openModal} = this.state;
        return (
            <div>
                <Button
                    id={"changePasswordButton"+this.props.userId}
                    color="secondary"
                    onClick={this.toggleModal}
                    className="ml-3"
                >
                    <MdLock />
                </Button>
                <Tooltip
                    placement="bottom"
                    isOpen={this.state.tooltipOpen}
                    target={"changePasswordButton"+this.props.userId}
                    toggle={this.toggle}
                    trigger="hover"
                    delay={{ show: 10, hide: 50 }}
                >
                    Cambiar contraseña
                </Tooltip>
                <Modal isOpen={openModal} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>
                        Cambiar contraseña
                    </ModalHeader>
                    <ChangePasswordForm
                        toggleModal={this.toggleModal}
                        onSubmit={this.submit}
                    />
                </Modal>
            </div>
        )
    }
}


const mapDisptachToProps = (dispatch) => ({
    changePassword: (userdId, password) => dispatch(changePassword(userdId, password))
});


export default connect(undefined, mapDisptachToProps)(ChangePasswordButton);