import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';
import { css } from 'react-emotion';
import ClipLoader from 'react-spinners/ClipLoader';
import './loading.dialog.scss';
 
const override = css`
    display: block;
    margin: 0 20px 0 5px;
    border-color: red;
`;

class LoadingDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }
    render() {
        return (
            <Modal isOpen={true} className="loading-dialog">
                <ModalBody>
                    <ClipLoader
                        className={override}
                        sizeUnit={"px"}
                        size={45}
                        color={'#f57c00'}
                        loading={this.state.loading}
                    />
                    <div className="dialog-message">
                        {this.props.message}
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}

LoadingDialog.propTypes = {
    message: PropTypes.string
}

LoadingDialog.defaultProps = {
    message: 'Loading ...'
}

export default LoadingDialog;


