import React, {Component} from 'react';
import {
    Navbar,
    NavbarBrand,
    NavbarToggler,
    Nav,
    NavItem,
    Modal, ModalHeader, ModalBody
} from 'reactstrap';
import {Link} from 'react-router-dom';
import {startLogout} from '../../actions/auth';
import {setCurrentHotel} from '../../actions/current.hotel';
import {connect} from 'react-redux';
import {history} from '../../routers/AppRouter';
import {MdAccountBalance} from 'react-icons/md';
import SwitchHotel from '../hotels/switch.hotel';
import Menu from '../menu/menu';
import {startSetHotels} from '../../actions/hotels';



export class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openChangeHotelModal: false,
            menuIsOpen: false
        };
    }

    componentDidMount(){
        //Loading all hotels
        this.props.startSetHotels();
    }

    toggleMenu = () => {
        this.setState(() => ({
            menuIsOpen: !this.state.menuIsOpen
        }))
    };

    toggleChangeHotelModal = (event) => {
        event.preventDefault();
        this.setState(() => ({
            openChangeHotelModal: !this.state.openChangeHotelModal
        }))
    };

    handleHotelChange = (event) => {
        const hotel_id = event.target.value;
        const hotel_selected = this.props.hotels.data.find((it) => it.id == hotel_id);
        this.props.changeHotel(hotel_selected);
        localStorage.current_hotel = JSON.stringify(hotel_selected);
        this.setState(() => ({
            openChangeHotelModal: !this.state.openChangeHotelModal
        }));
    };

    render() {
        const {currentUser, currentHotel, onLogout} = this.props;

        return (
            <div>
                <Navbar light expand="md" className="header">
                    <NavbarBrand href="/dashboard"><img className="logo" src="/images/logo-revhoteles.png"
                                                        alt=""/></NavbarBrand>

                    <div className="current-hotel d-none d-md-block">
                        {currentHotel.name &&
                        <div>
                            <MdAccountBalance size={20} className="hotel-icon"/>
                            <span className="hotel-name">{currentHotel.name}</span>
                        </div>
                        }
                    </div>


                    <NavbarToggler onClick={this.toggleMenu}/>
                    <Menu
                        user={currentUser}
                        onToggleChangeHotelModal={this.toggleChangeHotelModal}
                        onLogout={onLogout}
                        isOpen={this.state.menuIsOpen}
                    />
                </Navbar>
                <Navbar light expand="md" className="actions primary-heading">
                    <Nav>
                        <NavItem
                            className={`actions__link ${history.location.pathname == '/dashboard' ? 'active' : ''}`}>
                            <Link to="/dashboard" className="nav-link">Dashboard</Link>
                        </NavItem>
                        <NavItem
                            className={`actions__link ${history.location.pathname == '/forecast' ? 'active' : ''}`}>
                            <Link to="/forecast" className="nav-link">Pronóstico</Link>
                        </NavItem>
                        <NavItem className={`actions__link ${history.location.pathname == '/reports' ? 'active' : ''}`}>
                            <Link to="/reports" className="nav-link">Reportes</Link>
                        </NavItem>
                        <NavItem
                            className={`actions__link ${history.location.pathname == '/upload-file' ? 'active' : ''}`}>
                            <Link to="/upload-file" className="nav-link">Carga inicial de datos</Link>
                        </NavItem>
                    </Nav>
                </Navbar>
                <Modal isOpen={this.state.openChangeHotelModal} toggle={this.toggleChangeHotelModal}
                       className={this.props.className}>
                    <ModalHeader toggle={this.toggleChangeHotelModal}>Cambiar de hotel</ModalHeader>
                    <ModalBody>
                        <SwitchHotel currentHotel={currentHotel} hotels={currentUser.hotels}
                                     onHandleHotelChange={this.handleHotelChange}/>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}
;

const mapStateToProps = (state) => ({
    currentUser: state.current_user,
    currentHotel: state.current_hotel.hotel_info,
    hotels: state.hotels
});

const mapDispatchToProps = (dispatch) => ({
    onLogout: () => dispatch(startLogout()),
    changeHotel: (current_hotel) => dispatch(setCurrentHotel(current_hotel)),
    startSetHotels: () => dispatch(startSetHotels())
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);


