import React, {Component} from 'react';
import {loadCSVCalendarDays} from '../../actions/upload.csv.file';
import {Button, Alert, Card, CardBody, CardTitle} from 'reactstrap';
import moment from 'moment';
import CSVFileReader from './csv.file.reader';

class LoadCalendarDays extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            typeCSVFileSelected: 0,
            messageResponse: {}
        };
    }

    parsingCalendarDays = (dataMatrix) => {
        let dataResult = [];

        dataMatrix.forEach((it, index) => {
            if (index > 0) {
                let calendar_day = {};
                calendar_day['date'] = moment(it[1], 'DD/MM/YYYY').format('YYYY-MM-DD');
                calendar_day['day'] = it[2];
                calendar_day['year'] = it[3];
                calendar_day['period'] = it[4];
                calendar_day['comparison_day'] = it[5] ? moment(it[5], 'DD/MM/YYYY').format('YYYY-MM-DD') : null;
                calendar_day['comment'] = null;
                dataResult.push(calendar_day);
            }

        });
        return dataResult;
    }

    handleDataLoaded = (data) => {
        let dataMatrix = data.filter(it => it.length > 1);
        let dataResult = [];
        dataResult = this.parsingCalendarDays(dataMatrix);
        this.setState(() => ({
            data: dataResult
        }));
    }

    onProcessFileCalendarDays = () => {
        const {data} = this.state;
        loadCSVCalendarDays(data).then((response) => {
            this.setState(() => ({
                messageResponse: response
            }));
            setTimeout(() => {
                this.setState(() => ({
                    messageResponse: {}
                }))
            }, 3000)
        });
    };

    handleError = (error) => {
        console.log("Error", error)
    };

    render() {
        const {messageResponse, data} = this.state;

        return (
            <Card className="mt-3">
                <CardBody>
                    <CardTitle>Cargando datos para: <b>Calendar days</b></CardTitle>
                    {messageResponse.status && <div className={`upload-file-message ${messageResponse.status}`}>
                        <Alert color={`${messageResponse.status == 'has-success' ? 'success' : 'danger'}`}>
                            {messageResponse.message}
                        </Alert>
                    </div>}

                    <CSVFileReader
                        label="Seleccione un archivo"
                        onDataLoaded={this.handleDataLoaded}
                        onError={this.handleError}
                        inputId="csv-input-text"
                    />
                    {
                        data.length > 0 &&
                        <Button color="success" onClick={this.onProcessFileCalendarDays}>
                            Procesar archivo
                        </Button>
                    }
                </CardBody>
            </Card>
        )
    }
}

export default LoadCalendarDays;