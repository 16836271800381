import React, {Component} from 'react';
import {connect} from 'react-redux';
import {startSetHotels} from '../../actions/hotels';
import HotelList from './hotel.list';

class Hotels extends Component {

    render() {
        return (
            <div className="container">
                <HotelList />
            </div>
        )
    }

}

const mapDispatchToProps = (dispatch) => ({
    setHotels: () => dispatch(startSetHotels())
});

export default connect(undefined, mapDispatchToProps)(Hotels);