import {SET_USERS, ADD_USER, REMOVE_USER, EDIT_USER, REQUEST_USERS, CHANGE_PASSWORD, CLEAR_MESSAGE} from '../actions/types';
import {MESSAGE_TYPES} from '../utils/constants';

const usersDefaultState = {
    isLoadingUsers: false,
    data: [],
    message: {type: "", text: ""}
}


export default (state = usersDefaultState, action) => {

    switch (action.type) {
        case REQUEST_USERS:
            return {
                ...state,
                isLoadingUsers: true
            }
        case CLEAR_MESSAGE:
            return {
                ...state,
                message: {type: "", text: ""}
            }
        case SET_USERS:
            return {
                ...state,
                data: action.users,
                isLoadingUsers: false
            }
        case ADD_USER:
            return {
                ...state,
                data: [...state.data, action.user],
                message: {type: MESSAGE_TYPES.success, text: "Usuario adicionado correctamente"}
            };
        case EDIT_USER:
            const dataUpdated = state.data.map((user) => {
                if (user.id === action.id) {
                    return {
                        ...user,
                        ...action.updates
                    };
                } else {
                    return user;
                }
            })
            return {
                ...state,
                data: dataUpdated,
                message: {type: MESSAGE_TYPES.success, text: "El usuario fue actualizado correctamente"}
            };
        case REMOVE_USER:
            const newData = state.data.filter(({id}) => id !== action.id);
            return {
                ...state,
                data: newData,
                message: {type: MESSAGE_TYPES.success, text: "Se ha eliminado un usuario correctamente"}
            }
        case CHANGE_PASSWORD:
            return {
                ...state,
                message: {type: MESSAGE_TYPES.success, text: "Contraseña cambiada correctamente"}
            }
        default:
            return state;
    }
}