import React, {Component} from 'react';
import {connect} from 'react-redux';
import {removeUser} from '../../actions/users';
import {MdDelete} from 'react-icons/md';
import {Button, Modal, ModalHeader, ModalFooter, ModalBody, Tooltip} from 'reactstrap';


class RemoveUserButton extends Component {
    constructor(props) {
        super(props);
        this.state = {openModal: false, tooltipOpen: false}
    }

    toggleModal = (event) => {
        event.preventDefault();
        this.setState(() => ({
            openModal: !this.state.openModal,
        }))
    };

    toggle = () => {
        this.setState((prevState) => ({
            tooltipOpen: !prevState.tooltipOpen
        }))
    }

    removeUser = () => {
        const userId = this.props.userId;
        this.props.removeUser(userId);
    }

    render() {
        return (
            <div>
                <Button
                    id={"deleteUserButton"+this.props.userId}
                    color="danger"
                    disabled={this.props.isCurrentUser}
                    onClick={this.toggleModal}
                    className="ml-3"
                >
                    <MdDelete/>
                </Button>
                <Tooltip
                    placement="bottom"
                    isOpen={this.state.tooltipOpen}
                    target={"deleteUserButton"+this.props.userId}
                    toggle={this.toggle}
                    trigger="hover"
                    delay={{ show: 10, hide: 50 }}
                >
                    Eliminar usuario
                </Tooltip>
                <Modal isOpen={this.state.openModal} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>
                        Eliminar Usuario
                    </ModalHeader>
                    <ModalBody>
                        ¿Está seguro que desea eliminar este usuario?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.removeUser}>Estoy seguro</Button>
                        <Button color="secondary" onClick={this.toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    removeUser: (id) => dispatch(removeUser(id))
});

export default connect(undefined, mapDispatchToProps)(RemoveUserButton);