import React, {Component} from 'react';
import LoadForecast from './load.forecasts';
import LoadCalendarDays from './load.calendar.days';
import LoadOccupancies from './load.occupancies';
import LoadPriceLogs from './load.price.logs';


class LoadCSVFile extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div>
                <LoadForecast />
                <LoadOccupancies />
                {/*<LoadCalendarDays />*/}
                <LoadPriceLogs />
            </div>
        )
    }
}


export default LoadCSVFile;