import React from 'react';

class SelectField extends React.Component {
    render() {
        const meta = this.props.meta;
        const options = this.props.options && this.props.options.length > 0 ? this.props.options.map((option) => {
            return <option key={option.value || 'undefined'} value={option.value}>{option.text}</option>;
        }) : [];

        return (
            <div className={`form-group ${meta.touched && meta.error ? 'has-error' : ''}`}>
                <label className={`control-label ${this.props.required ? 'required' : ''}`}>
                    {this.props.label} {this.props.required ? <span>*</span> : ''}
                </label>
                <select {...this.props.input} className={`form-control ${meta.touched && meta.error ? 'has-error' : ''}`}>
                    <option value="">Elegí uno...</option>
                    {options}
                </select>
                {meta.touched && meta.error &&
                    <small className="form-text text-danger">{meta.error}</small>
                }
            </div>
        );
    }
}

export default SelectField;