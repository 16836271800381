import React, {Component} from 'react';
import {MdAdd} from 'react-icons/md';
import {Button, Modal, ModalHeader} from 'reactstrap';
import UserForm from './user.form';
import {connect} from 'react-redux';
import {addUser} from '../../actions/users';

class AddUserButton extends Component {
    constructor(props) {
        super(props);
        this.state = {openModal: false}
    }

    toggleModal = (event) => {
        event.preventDefault();
        this.setState(() => ({
            openModal: !this.state.openModal,
        }))
    };

    submit = (values) => {
        delete values.confirmPassword;
        const userInfo = JSON.stringify(values, null, 2);
        this.props.addUser(userInfo);
        this.setState(() => ({
            openModal: false
        }))
    };

    render() {
        const {openModal} = this.state;
        return (
            <div>
                <Button
                    color="success"
                    onClick={this.toggleModal}
                >
                    <MdAdd
                        size={20}
                        className="mr-2"
                    />
                    Nuevo Usuario
                </Button>
                <Modal isOpen={openModal} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>
                        Adicionando Usuario
                    </ModalHeader>
                    <UserForm
                        roles={this.props.roles}
                        toggleModal={this.toggleModal}
                        onSubmit={this.submit}
                    />
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    roles: state.roles
});

const mapDispatchToProps = (dispatch) => ({
    addUser: (userInfo) => dispatch(addUser(userInfo))
});


export default connect(mapStateToProps, mapDispatchToProps)(AddUserButton);