import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import Header from '../components/header/header';

export const PrivateRoute = ({
    isAuthenticated,
    component: Component,
    ...rest
}) => (
    <Route {...rest} component={(props) => (
        isAuthenticated ? (
                <div>
                    <Header />
                    <div className="container">
                        <div className="content-container">
                            <Component {...props}/>
                        </div>
                    </div>
                </div>
            ) : (
                <Redirect to="/"/>
            )
    )}/>
);


const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.logged
});

export default connect(mapStateToProps)(PrivateRoute);


