import React, {Component} from 'react';
import {loadCSVPriceLogs} from '../../actions/upload.csv.file';
import {Button, Alert, Card, CardBody, CardTitle} from 'reactstrap';
import moment from 'moment';
import CSVFileReader from './csv.file.reader';

class LoadPriceLogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            messageResponse: {}
        };
    }

    parsingPriceLogs = (dataMatrix) => {
        let dataResult = [];

        dataMatrix.forEach((it, index) => {
            if (index > 0) {
                let  price_log = {};
                price_log['snapshot_date'] = moment('11/01/2016', 'MM/DD/YYYY').format('YYYY-MM-DD');
                price_log['calendar_date'] = moment(it[2], 'MM/DD/YYYY').format('YYYY-MM-DD');
                price_log['room_type_id'] = it[1];
                price_log['days_in_advance'] = moment(price_log['calendar_date']).diff(price_log['snapshot_date'], 'days');
                price_log['price'] = it[3];
                price_log['old_price'] = null;
                dataResult.push(price_log);
            }

        });

        return dataResult;
    }

    handleDataLoaded = (data) => {
        let dataMatrix = data.filter(it => it.length > 1);
        let dataResult = [];
        dataResult = this.parsingPriceLogs(dataMatrix);
        this.setState(() => ({
            data: dataResult
        }));
    }

    onProcessFilePriceLogs = () => {
        const {data} = this.state;
        loadCSVPriceLogs(data).then((response) => {
            this.setState(() => ({
                messageResponse: response
            }));
            setTimeout(() => {
                this.setState(() => ({
                    messageResponse: {}
                }))
            }, 3000)
        });
    };

    handleError = (error) => {
        console.log("Error", error)
    };

    render() {
        const {messageResponse, data} = this.state;

        return (
            <Card className="mt-3">
                <CardBody>
                    <CardTitle>Cargando datos para: <b>Price Logs</b></CardTitle>
                    {messageResponse.status && <div className={`upload-file-message ${messageResponse.status}`}>
                        <Alert color={`${messageResponse.status == 'has-success' ? 'success' : 'danger'}`}>
                            {messageResponse.message}
                        </Alert>
                    </div>}

                    <CSVFileReader
                        label="Seleccione un archivo"
                        onDataLoaded={this.handleDataLoaded}
                        onError={this.handleError}
                        inputId="csv-input-text"
                    />
                    {
                        data.length > 0 &&
                        <Button color="success" onClick={this.onProcessFilePriceLogs}>
                            Procesar archivo
                        </Button>
                    }
                </CardBody>
            </Card>
        )
    }
}

export default LoadPriceLogs;