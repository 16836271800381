import {SET_CURRENT_USER, CLEAR_CURRENT_USER} from '../actions/types';

export default (state = {}, action) => {
    switch (action.type){
        case SET_CURRENT_USER:
            return{
                ...state,
                ...action.current_user
            }
        case CLEAR_CURRENT_USER:
            return action.current_user
        default:
            return state;
    }
};