import React, {Component} from 'react';
import {MdAdd} from 'react-icons/md';
import {Button, Modal, ModalHeader} from 'reactstrap';
import RoomForm from './room.form';
import {connect} from 'react-redux';
import {addRoom} from '../../actions/rooms';
import {startSetHotels} from '../../actions/hotels';
import {setCurrentHotel} from '../../actions/current.hotel';

class AddRoomButton extends Component {
    constructor(props) {
        super(props);
        this.state = {openModal: false}
    }

    toggleModal = (event) => {
        event.preventDefault();
        this.setState(() => ({
            openModal: !this.state.openModal,
        }))
    };

    submit = (values) => {
        const roomInfo = JSON.stringify(values, null, 2);
        this.props.addRoom(roomInfo);
        this.props.setHotels();
        this.setState(() => ({
            openModal: false
        }))
    };

    render() {
        const {openModal} = this.state;
        return (
            <div>
                <Button
                    color="success"
                    onClick={this.toggleModal}
                >
                    <MdAdd
                        size={20}
                        className="mr-2"
                    />
                    Nueva habitación
                </Button>
                <Modal isOpen={openModal} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>
                        Adicionar habitación
                    </ModalHeader>
                    <RoomForm
                        hotels={this.props.hotels}
                        toggleModal={this.toggleModal}
                        onSubmit={this.submit}
                    />
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    hotels: state.hotels,
    currentHotelId: state.current_hotel.hotel_info.id
});

const mapDispatchToProps = (dispatch) => ({
    addRoom: (roomInfo) => dispatch(addRoom(roomInfo)),
    setHotels: () => dispatch(startSetHotels()),
    setCurrentHotel: (hotelInfo) => dispatch(setCurrentHotel(hotelInfo))
});


export default connect(mapStateToProps, mapDispatchToProps)(AddRoomButton);