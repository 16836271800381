import React, {Component} from 'react';
import {MdAdd} from 'react-icons/md';
import {Button, Modal, ModalHeader} from 'reactstrap';
import HotelForm from './hotel.form';
import {connect} from 'react-redux';
import {addHotel} from '../../actions/hotels';
import {getCurrentUser} from '../../actions/current.user';

class AddHotelButton extends Component {
    constructor(props) {
        super(props);
        this.state = {openModal: false}
    }

    toggleModal = (event) => {
        event.preventDefault();
        this.setState(() => ({
            openModal: !this.state.openModal,
        }))
    };

    submit = (values) => {
        const hotelInfo = JSON.stringify(values, null, 2);
        this.props.addHotel(hotelInfo);
        this.props.setCurrentUser();
        this.setState(() => ({
            openModal: false
        }))
    };

    render() {
        const {openModal} = this.state;
        return (
            <div>
                <Button
                    color="success"
                    onClick={this.toggleModal}
                >
                    <MdAdd
                        size={20}
                        className="mr-2"
                    />
                    Nuevo Hotel
                </Button>
                <Modal isOpen={openModal} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>
                        Adicionando Hotel
                    </ModalHeader>
                    <HotelForm
                        toggleModal={this.toggleModal}
                        onSubmit={this.submit}
                    />
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    roles: state.roles
});

const mapDisptachToProps = (dispatch) => ({
    addHotel: (hotelInfo) => dispatch(addHotel(hotelInfo)),
    setCurrentUser: () => dispatch(getCurrentUser())
});


export default connect(mapStateToProps, mapDisptachToProps)(AddHotelButton);