import React, {Component} from 'react';
import {MdEdit} from 'react-icons/md';
import {Button, Modal, ModalHeader, Tooltip} from 'reactstrap';
import HotelForm from './hotel.form';
import {connect} from 'react-redux';
import {editHotel} from '../../actions/hotels';
import Roles from '../../roles';
import {SubmissionError} from 'redux-form';

class EditHotelButton extends Component {
    constructor(props) {
        super(props);
        this.state = {openModal: false, tooltipOpen: false}
    }

    toggleModal = (event) => {
        event.preventDefault();
        this.setState(() => ({
            openModal: !this.state.openModal,
        }))
    };

    toggle = () => {
        this.setState((prevState) => ({
            tooltipOpen: !prevState.tooltipOpen
        }))
    }

    submit = (values) => {

        const hotelInfo = JSON.stringify(values, null, 2);
        this.props.editHotel(this.props.hotelId, hotelInfo);
        this.setState(() => ({
            openModal: false
        }))

    };

    render() {
        const {openModal} = this.state;

        return (
            <div>
                <Button
                    id={"editHotelButton"+this.props.hotelId}
                    color="info"
                    onClick={this.toggleModal}
                    className="ml-3"
                >
                    <MdEdit />
                </Button>
                <Tooltip
                    placement="bottom"
                    isOpen={this.state.tooltipOpen}
                    target={"editHotelButton"+this.props.hotelId}
                    toggle={this.toggle}
                    trigger="hover"
                    delay={{ show: 10, hide: 50 }}
                >
                    Editar Hotel
                </Tooltip>
                <Modal isOpen={openModal} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>
                        Editar Hotel
                    </ModalHeader>
                    <HotelForm
                        toggleModal={this.toggleModal}
                        onSubmit={this.submit}
                        hotelId={this.props.hotelId}
                    />
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    roles: state.roles,
    users: state.users.data
});

const mapDisptachToProps = (dispatch) => ({
    editHotel: (hotelId, hotelInfo) => dispatch(editHotel(hotelId, hotelInfo))
});


export default connect(mapStateToProps, mapDisptachToProps)(EditHotelButton);