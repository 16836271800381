import React from 'react';
import { css } from 'react-emotion';
import SyncLoader from 'react-spinners/SyncLoader';
const override = css`
    display: block;
    margin: 2px;
    border-color: red;
`;
import './loading.shading.scss';

export const LoadingShading = () => (
    <div className="e-loading-shading">
        <div className="e-loading-icon">
            <SyncLoader
                className={override}
                sizeUnit={"px"}
                size={12}
                color={'#f57c00'}
                loading={true}
            />
        </div>
    </div>
)
