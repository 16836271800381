import React, {Component} from 'react';
import {loadCSVOccupancies} from '../../actions/upload.csv.file';
import {Button, Alert, Card, CardBody, CardTitle} from 'reactstrap';
import moment from 'moment';
import CSVFileReader from './csv.file.reader';

class LoadOccupancies extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            typeCSVFileSelected: 0,
            messageResponse: {}
        };
    }

    parsingOccupancies = (dataMatrix) => {
        let dataResult = [];

        dataMatrix.forEach((it, index) => {
            if (index > 0) {
                let occupancie = {};
                occupancie['room_type_id'] = it[1] ;
                occupancie['calendar_date'] = it[2];
                occupancie['quantity_reserved_rooms'] = it[4];
                occupancie['amount'] = parseFloat(it[5].replace(',', '.'));
                occupancie['quantity_pax'] = it[7];
                dataResult.push(occupancie);
            }

        });
        return dataResult;
    }

    handleDataLoaded = (data) => {
        let dataMatrix = data.filter(it => it.length > 1);
        let dataResult = [];
        dataResult = this.parsingOccupancies(dataMatrix);
        this.setState(() => ({
            data: dataResult
        }));
    }

    onProcessFileOccupancies = () => {
        const {data} = this.state;
        loadCSVOccupancies(data).then((response) => {
            this.setState(() => ({
                messageResponse: response
            }));
            setTimeout(() => {
                this.setState(() => ({
                    messageResponse: {}
                }))
            }, 3000)
        });
    };

    handleError = (error) => {
        console.log("Error", error)
    };

    render() {
        const {messageResponse, data} = this.state;

        return (
            <Card className="mt-3">
                <CardBody>
                    <CardTitle>Cargando datos para: <b>Occupancies</b></CardTitle>
                    {messageResponse.status && <div className={`upload-file-message ${messageResponse.status}`}>
                        <Alert color={`${messageResponse.status == 'has-success' ? 'success' : 'danger'}`}>
                            {messageResponse.message}
                        </Alert>
                    </div>}

                    <CSVFileReader
                        label="Seleccione un archivo"
                        onDataLoaded={this.handleDataLoaded}
                        onError={this.handleError}
                        inputId="csv-input-text"
                    />
                    {
                        data.length > 0 &&
                        <Button color="success" onClick={this.onProcessFileOccupancies}>
                            Procesar archivo
                        </Button>
                    }
                </CardBody>
            </Card>
        )
    }
}

export default LoadOccupancies;